import React from 'react';
import PropTypes from 'prop-types';
import Imm from 'immutable';
import { connect } from 'react-redux';
import { primaryFigureSelector, primaryFigureIdentifierSelector } from '../../helper/selector/selectionSelectors';
import { isFigureReadonly, getFigureType } from '../../helper/figureConfigHelper';
import { deleteFigureConfig } from '../../actions/DesignerActions';
import { figureIdentifierShape } from '../../helper/shapes';

/**
 * Specialized configuration options for descriptor figures
 */

function DescriptorConfigHeader(props) {
    const figureIdentifier = props.figureIdentifier;
    const figure = props.figure;
    const isReadonly = isFigureReadonly(figure);
    const figureType = getFigureType(figure);

    return <div className="config-header-form">
        <div>Descriptor details:</div>
        <div><label>Type: { figureType }</label></div>

        { !isReadonly
            ? <button onClick={
                () => props.deleteFigureConfig(figureIdentifier)}>Delete</button>
            : <span className="readonly-warn">Read-only</span> }
    </div>
}

DescriptorConfigHeader.propTypes = {
    designId: PropTypes.string.isRequired,

    // connect
    figure: PropTypes.instanceOf(Imm.Map).isRequired,
    figureIdentifier: PropTypes.shape(figureIdentifierShape).isRequired,
    selectionType: PropTypes.string.isRequired,
    deleteFigureConfig: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => ({
        figure: primaryFigureSelector(state, designId),
        figureIdentifier: primaryFigureIdentifierSelector(state, designId)
    }),
    { deleteFigureConfig }
)(DescriptorConfigHeader);
