import PropTypes from 'prop-types';

export const figureIdentifierShape = PropTypes.shape({
    figureId: PropTypes.string.isRequired,
    figureType: PropTypes.string.isRequired,
    designId: PropTypes.string.isRequired
});

export const resourceShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    directory: PropTypes.bool.isRequired,
    sub: PropTypes.array.isRequired
});

