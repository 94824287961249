import React from 'react'
import PropTypes from 'prop-types';
import { fromJS, Set, List, Map } from 'immutable'
import { connect } from 'react-redux';
import {includeFigureProperty, excludeFigureProperty, cancelFigureConfigDetails, acceptFigureConfigDetails} from '../../actions/DesignerActions'
import { availablePropKeysForSelectionSelector, propKeysForSelectionSelector, configDetailsConfigPathsSelector } from '../../helper/selector/configDetailsSelectors';

import { GROUPS } from '../../helper/DesignerHelper';

class FigureConfigurationDetails extends React.Component {
  
  constructor(props) {
    super();
    const fields = this.getFieldSelections(props);
    const groups = this.getFieldGroups(props);
    this.state = {
      groups,
      fields
    }
  }

  getFieldSelections(props) {
    return props.availablePropKeys.reduce((acc, cfp) => {
      const key = cfp.get('key');
      const selected =  this.fieldWasCheckedBefore(props, key);
      return acc.set(key, selected);
    }, Map())
  }

  getFieldGroups(props) {
    function sortBy(path) {
      return (a, b) => a.getIn(path, 0) - b.getIn(path, 0);
    }

    const lookup = {};
    const groups = [];

    for(let field of props.availablePropKeys) {
      const groupId = field.get('group');
      if(groupId != null) {

        let idx = lookup[groupId];
        if(idx == null) {
          // create new group
          lookup[groupId] = idx = groups.length;
          groups.push({
            fields: [],
            group: GROUPS.get(groupId)
          });
        }

        // add this field to the group
        groups[idx].fields.push(field);
      }
    }

    // js -> immutable. Apply sorting
    return fromJS(groups)
      .sort(sortBy(['group', 'order']))
      .map((gp) => gp.update('fields', (fields) => fields
        .sort(sortBy(['order']))
      ));
  }

  fieldWasCheckedBefore(props, fieldKey) {
    return props.propKeys.contains(fieldKey);
  }
 
  includeAllFigurePropertiesForField(fieldKey) {
    // include field actions for every selected figure
    return this.props.figurePaths.reduce((l, configPath) => l.push(
        includeFigureProperty(configPath, fieldKey)), List());
  }

  excludeAllFigurePropertiesForField(fieldKey) {
    // exclude field actions for every selected figure
    return this.props.figurePaths.reduce((l, configPath) => l.push(
        excludeFigureProperty(configPath, fieldKey)), List());
  }

  doAccept() {
    const configChangeActions = this.state.fields.reduce((list, activeNow, fieldKey) => {
      const activeBefore = this.fieldWasCheckedBefore(this.props, fieldKey);
      if (activeBefore != activeNow) {
        if (activeNow) {
          return list.concat(
            this.includeAllFigurePropertiesForField(fieldKey));

        } else {
          return list.concat(
            this.excludeAllFigurePropertiesForField(fieldKey));

        }
      }
      return list
    }, List())
    this.props.acceptFigureConfigDetails(this.props.designId, configChangeActions)
  }

  doCancel() {
    this.props.cancelFigureConfigDetails(this.props.designId)
  }

  doSelect(fieldId) {
    this.setState({ fields: this.state.fields.update(fieldId, (f) => !f) });
  }

  doSelectGroup(groupIdx, value) {
    const fields = this.state.groups.getIn([groupIdx, 'fields']);
    this.setState({ fields: fields.reduce((acc, field) => acc.set(field.get('key'), value), this.state.fields) });
  }

  isGroupSelected(groupIdx) {
    const fields = this.state.groups.getIn([groupIdx, 'fields']);
    return fields.every((field) => this.state.fields.get(field.get('key')));
  }

  renderField(field) {
    const key = field.get('key');
    const description = field.get('description');
    const selected = this.state.fields.get(key);

    const doSelect = this.doSelect.bind(this, key);
    return <div key={key} className="figure-prop-field" onClick={doSelect}>
      <div>
        <input type='checkbox' checked={selected} onChange={doSelect}/>
        <span className='figure-prop-key hover-clickable'>{key}</span>
      </div>
      <div className='figure-prop-description'>{description}</div>
    </div>
  }

  renderFieldGroup(group, groupIdx) {
    const gp = group.get('group');
    const groupId = gp.get('id');
    const isGroupSelected = this.isGroupSelected(groupIdx);
    return <div key={groupId} className='figure-props-config-group'>
        <div className="header">
          <input className="group-cb" type='checkbox' checked={isGroupSelected} onChange={this.doSelectGroup.bind(this, groupIdx, !isGroupSelected)}/>
          { gp.get('display') }
        </div>
        <div className="fields">
          {group.get('fields').map(this.renderField.bind(this))}
        </div>
      </div>
  }

  render() {
    return <div>
      <div className='figure-props-config'>
        {this.state.groups.map(this.renderFieldGroup.bind(this))}
      </div>
      <input type='button' value='Accept' onClick={this.doAccept.bind(this)}/>
      <input type='button' value='Cancel' onClick={this.doCancel.bind(this)}/>
    </div>
  }
}

FigureConfigurationDetails.propTypes = {
  designId: PropTypes.string.isRequired,
  
   // connect
  figurePaths: PropTypes.instanceOf(List).isRequired,
  availablePropKeys: PropTypes.instanceOf(Set).isRequired,
  propKeys: PropTypes.instanceOf(Set).isRequired,
  cancelFigureConfigDetails: PropTypes.func.isRequired,
  acceptFigureConfigDetails: PropTypes.func.isRequired
}

export default connect(
  (state, { designId }) => {
    return {
      figurePaths: configDetailsConfigPathsSelector(state, designId),
      availablePropKeys: availablePropKeysForSelectionSelector(state, designId),
      propKeys: propKeysForSelectionSelector(state, designId)
    }
  }, 
  { cancelFigureConfigDetails, acceptFigureConfigDetails }
)(FigureConfigurationDetails);
