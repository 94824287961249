import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { setCanvasContextMenuToggled, assignToParent, unassignFromParent, copyFigure } from '../actions/DesignerActions';
import { getCurrentDesignId } from '../helper/selector/selectors';
import { primaryFigureIdentifierSelector, secondaryFigureIdsSelector } from '../helper/selector/selectionSelectors';
import { canAssignToParent, canUnassignFromParent } from '../helper/selector/contextMenuSelectors';

function CanvasContextMenuItem(props) {
    return <div className="canvas-context-menu-item" onClick={props.clickAction}>
        { props.label }
    </div>
}

CanvasContextMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    clickAction: PropTypes.func.isRequired
}

class CanvasContextMenu extends React.Component {

    UNSAFE_componentWillMount() {
        this.resizeHandle = window.addEventListener('resize', this.handleClosed.bind(this));
        this.docClickHandle = document.addEventListener('click', this.handleClicked.bind(this));
    }

    render() {
        let isToggled = false;
        if(this.props.toggled) {
            isToggled = this.props.toggled.get('isToggled');
        }

        return <div className={ 'canvas-context-menu' + (isToggled ? ' canvas-context-menu--active' : '') } 
                ref={(divElem) => this.updatePosition(divElem)}>
                <div className="canvas-context-menu-header">Menu</div>
                { this.getMenuItems() }
            </div>
    }

    getMenuItems() {
        const menuItems = [];
        if(this.props.primary && this.props.primary.figureType === 'canvas') {
            menuItems.push(<CanvasContextMenuItem key="item-copy" label="Copy" clickAction={this.wrapItemAction(this.props.copyFigure)}/>);

            if(this.props.canAssignToParent) {
                menuItems.push(<CanvasContextMenuItem key="item-assign-to-parent" label="Set Parent" clickAction={this.wrapItemAction(this.handleAssignToParent.bind(this))}/>);
            }

            if(this.props.canUnassignFromParent) {
                menuItems.push(<CanvasContextMenuItem key="item-unassign-from-parent" label="Clear Parent" clickAction={this.wrapItemAction(this.handleUnassignFromParent.bind(this))}/>)
            }
        }
        return menuItems;
    }

    handleAssignToParent() {
        this.props.assignToParent(
            this.props.designId,
            this.props.primary.figureId,
            this.props.secondaryIds);
    }

    handleUnassignFromParent() {
        this.props.unassignFromParent(
            this.props.designId,
            this.props.primary.figureId,
            this.props.secondaryIds);
    }

    wrapItemAction(actionFn) {
        return () => {
            this.handleClosed();
            actionFn();
        }
    }

    /**
     * calculate position of the menu, taking into account dimensions of the window
     * @param {HTMLElement} divElem
     */
    updatePosition(divElem) {
        if(!divElem) return;

        let posX = 0, posY = 0;
        if(this.props.toggled) {
            posX = this.props.toggled.getIn(['position', 'x']);
            posY = this.props.toggled.getIn(['position', 'y']);
        }

        const width = divElem.offsetWidth;
        const height = divElem.offsetHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if(posX + width > windowWidth) {
            posX = windowWidth - width;
        }   

        if(posY + height > windowHeight) {
            posY = windowHeight - height;
        }

        divElem.style.left = posX + 'px';
        divElem.style.top = posY + 'px';
    }

    clickInsideElement(e) {
        /** @type {HTMLElement} */
        let el = e.srcElement || e.target;

        if ( el.classList.contains('canvas-context-menu') ) {
            return true;
        } else {
            while (el = el.parentNode) {
                if ( el.classList && el.classList.contains('canvas-context-menu') ) {
                    return true;
                }
            }
        }

        return false;
    }

    handleClosed() {
        this.props.setCanvasContextMenuToggled(false, null);
    }

    handleClicked(e) {
        // only close the context menu when showing
        if(!this.clickInsideElement(e)
            && this.props.toggled && this.props.toggled.get('isToggled')) {
            this.handleClosed();
        }
    }

    onComponentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandle);
        document.removeEventListener('click', this.docClickHandle);
    }
}

CanvasContextMenu.propTypes = {
    primary: PropTypes.object,
    secondaryIds: PropTypes.instanceOf(List),
    toggled: PropTypes.object,
    canAssignToParent: PropTypes.bool.isRequired,
    canUnassignFromParent: PropTypes.bool.isRequired,
    designId: PropTypes.string.isRequired,
    setCanvasContextMenuToggled: PropTypes.func.isRequired,
    copyFigure: PropTypes.func.isRequired,
    assignToParent: PropTypes.func.isRequired,
    unassignFromParent: PropTypes.func.isRequired
}

export default connect(
    (state) => {
        const designId = getCurrentDesignId(state);
        return {
            toggled: state.designer.get('canvas-context-menu-toggled'),
            canAssignToParent: canAssignToParent(state, designId),
            canUnassignFromParent: canUnassignFromParent(state, designId),
            primary: primaryFigureIdentifierSelector(state, designId),
            secondaryIds: secondaryFigureIdsSelector(state, designId),
            designId
        }
    },
    { setCanvasContextMenuToggled, 
        copyFigure,
        assignToParent,
        unassignFromParent }
)(CanvasContextMenu);
