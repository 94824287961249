import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Modal, Button } from 'react-bootstrap';

import { getModalConfig, isModalVisible, createFormInitialValuesSelector } from '../utils/selector/modalSelectors';
import { modalConfigShape } from '../models/modalShapes';
import { hideModal, setModalValues } from '../actions';
import createModalContentFactory from './createModalContentFactory';

/**
 * A generic modal container that shows content based on an id
 */

 class GenericModalComponent extends React.Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!this.props.modalVisible && nextProps.modalVisible && nextProps.dirty) {
            // clear the form when the modal is shown
            this.props.reset();
        }
    }

    render() {
        if(!this.props.modalConfig)
            return <div></div>

        const content = createModalContent(this.props.modalConfig.get('body'), this.props.componentFactory, {
            formValueSelector: this.props.formValueSelector,
            formId: this.props.modalId
        });

        return <Modal show={this.props.modalVisible} onHide={() => this.props.hideModal(this.props.modalId)}>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.props.modalConfig.get('title') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { content }
                </Modal.Body>
                <Modal.Footer>
                    { this.props.modalConfig.get('actions').map((action, idx) => {
                            const label = action.get('label');
                            const style = action.get('style');
                            const actionHandler = createActionHandler(this.props.modalId, this.props.handleSubmit, this.props.hideModal, action);
                            return <Button key={idx}
                                bsStyle={style}
                                onClick={actionHandler}> { label } </Button>
                        })
                    }
                </Modal.Footer>
            </Modal>
    }
 }

function createActionHandler(modalId, handleSubmitFn, hideModalFn, action) {
    const type = action.get('type');
    const actionFn = action.get('action');
    function callback(values) {
        hideModalFn(modalId);
        actionFn(values);
    }

    if(type === 'submit') {
        return handleSubmitFn(callback);
    } else {
        return callback;
    }
}

function createModalContent(contentConfig, componentFactory, modalParams) {
    if(!contentConfig || !componentFactory) return;

    const contentId = contentConfig.get('componentId');
    let contentParams = contentConfig.get('params');
    contentParams && (contentParams = contentParams.toObject());

    const ContentComponent = componentFactory(contentId);
    return React.createElement(ContentComponent, Object.assign(
        modalParams,
        contentParams));
}

GenericModalComponent.propTypes = {
    // ownprops
    modalId: PropTypes.string.isRequired,
    componentsMap: ImmutablePropTypes.map,

    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    formValueSelector: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    modalConfig: modalConfigShape,
    componentFactory: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    setModalValues: PropTypes.func.isRequired
}

export default connect(
    (state, { modalId, componentsMap }) => {
        const componentFactory = createModalContentFactory(componentsMap);
        const getInitialValues = createFormInitialValuesSelector(modalId);
        const _formValueSelector = formValueSelector(modalId);
        return (state) => ({
            form: modalId,
            formValueSelector: _formValueSelector,
            initialValues: getInitialValues(state),
            modalVisible: isModalVisible(state, modalId),
            modalConfig: getModalConfig(state, modalId),
            componentFactory
        })
    },
    { hideModal, setModalValues }
)(reduxForm({
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(GenericModalComponent));
