import { createSelector } from 'reselect';

export function getModal(state, modalId) {
    return state.componentLib.getIn(['modal', modalId]);
}

export function getModalConfig(state, modalId) {
    const modal = getModal(state, modalId);
    return modal && modal.get('config');
}

export function getModalValues(state, modalId) {
    const modal = getModal(state, modalId);
    return modal && modal.get('values');
}

export function isModalVisible(state, modalId) {
    const modal = getModal(state, modalId);
    return modal ? modal.get('showing') : false;
}

export const createFormInitialValuesSelector = (modalId) => createSelector(
    (state) => getModalValues(state, modalId),
    (values) => values && values.toJS());
