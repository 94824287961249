import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { triggerRefreshDatasources } from '../../actions/DesignerActions';

import { getPollInterval } from '../../helper/selector/selectors';

/**
 * Triggers datasource updates on a schedule
 */

class DatasourcesPoller extends React.Component {
    constructor() {
        super();
        this.trigger = this.trigger.bind(this);
    }

    componentDidMount() {
        if(this.props.pollInterval) {
            this.pollHandle = window.setInterval(this.trigger, this.props.pollInterval);
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.pollHandle);
    }

    trigger() {
        this.props.triggerRefreshDatasources();
    }

    render() {
        return React.Children.only(this.props.children)
    }
}

DatasourcesPoller.propTypes = {
    pollInterval: PropTypes.number,
    children: PropTypes.node.isRequired,
    triggerRefreshDatasources: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => ({
        pollInterval: getPollInterval(state, designId)
    }),
    { triggerRefreshDatasources }
)(DatasourcesPoller)
