//import PurchaseActionTypes from '../actions/PurchaseActions'

function purchase(state = [], action) {
  switch(action.type) {
    default:
      return state;
  }
}

export default purchase
