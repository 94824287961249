import { Map } from 'immutable';

/**
 * Designer modal content components
 */

import ContentEditReportTransform, { CONTENT_EDIT_REPORT_TRANSFORM } from './EditReportTransformForm';
import CanvasConfigModalForm, { CANVAS_CONFIG_FORM } from '../header/CanvasConfigModal';
import Gallery, { MODAL_GALLERY } from '../gallery/GalleryModal';

export default Map([
    [CONTENT_EDIT_REPORT_TRANSFORM, ContentEditReportTransform],
    [CANVAS_CONFIG_FORM, CanvasConfigModalForm],
    [MODAL_GALLERY, Gallery]
]);
