//import PartnerActionTypes from '../actions/PartnerActions'

function partner(state = [], action) {
  switch(action.type) {
    default:
      return state;
  }
}

export default partner;
