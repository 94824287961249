import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, initialize, Field, FieldArray } from 'redux-form';
import { Alert } from 'react-bootstrap';

import FormGroupField from '../common/FormGroupField';
import FormGroupMultiField from '../common/FormGroupMultiField';
import AutoSuggestField from '../common/AutosuggestField';
import { transformDefinitions, transformDefinitionKeys } from '../../data/reportTransforms';
import { findReportDefinitionByName } from '../../actions/reportActions';
import { validateTransformType, validateFieldArray, validateField } from './reportTransformValidator';
import { getReportDefinition, getFetchingReportDefinition, getReportDefinitionHasError, getReportColumns } from '../../helper/selector/reportSelector';

/**
 * Modal form for editing report transform
 */

export const CONTENT_EDIT_REPORT_TRANSFORM = 'CONTENT_EDIT_REPORT_TRANSFORM'

function getInputComponent(name, param, reportColumns) {
    const componentProps = {};
    let component = 'input';
    switch(param.type) {
        case 'dropdown-column': {
            component = AutoSuggestField;
            Object.assign(componentProps, {
                datalist: reportColumns.toArray(),
                placeholder: 'Report Column'
            });
        }
    }
    return {
        component,
        componentProps };
}

function createArrayField(name, param, reportColumns) {
    const { component, componentProps } = getInputComponent(name, param, reportColumns);
    return <FieldArray
        key={param.key}
        name={`${name}.${param.key}`}
        label={param.label}
        component={FormGroupMultiField}
        validate={validateFieldArray(param)}
        inputComponent={component}
        inputProps={componentProps}/>
}

function createField(name, param, reportColumns) {
    const { component, componentProps } = getInputComponent(name, param, reportColumns);
    return <Field
        key={param.key}
        name={`${name}.${param.key}`}
        label={param.label}
        component={FormGroupField}
        validate={validateField(param)}
        inputComponent={component}
        inputProps={componentProps}/>
}

function createParamFields(transformType, reportColumns) {
    const paramFields = [];
    if(transformType) {
        const transformDefinition = transformDefinitions.get(transformType);
        if(transformDefinition != null) {
            for(let param of transformDefinition.params) {
                const field = param.multi
                    ? createArrayField('params', param, reportColumns)
                    : createField('params', param, reportColumns);

                paramFields.push(field);
            }
        }
    }
    return paramFields;
}

function TransformParamsComponent(props) {
    const paramFields = [];
    if(props.transformType) {
        const transformDefinition = transformDefinitions.get(props.transformType);
        if(transformDefinition != null) {
            for(let param of transformDefinition.params) {
                let field;
                if(param.multi) {
                    field = createArrayField(props.input.name, param, props.reportColumns);
                } else {
                    field = createField(props.input.name, param, props.reportColumns);
                }
                paramFields.push(field);
            }
        }
    }
    return <div>{ paramFields }</div>
}

TransformParamsComponent.propTypes = {
    transformType: PropTypes.string,
    input: PropTypes.object.isRequired,
    reportColumns: ImmutablePropTypes.list.isRequired
}

class EditReportTransformForm extends React.Component {
    constructor() {
        super();
        this.state = { warningDismissed: false };
    }

    UNSAFE_componentWillMount() {
        if(this.props.reportName
            && this.props.reportDefinition == null
            && !this.props.fetchingReportDefinition) {
            this.props.findReportDefinitionByName(this.props.reportName);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const transformType = nextProps.transformType;
        if(transformType !== this.props.transformType) {
            // re-initialize the form with the default values for the new transform type
            const transformDefinition = transformDefinitions.get(transformType);
            const params = {};
            if(transformDefinition != null) {
                for(let param of transformDefinition.params) {
                    params[param.key] = param.defaultValue
                        ? param.defaultValue : param.multi
                        ? [''] : '';
                }
            }

            const data = {};
            data.type = transformType;
            data.params = params;
            this.props.initialize(this.props.formId, data);
        }
    }

    getWarningMsg() {
        if(!this.props.reportName) {
            return 'No report was specified. Columns will be empty.'
        } else if(this.props.reportDefinitionHasError) {
            return 'Error fetching report definition. Columns will be empty.'
        } else if(this.props.fetchingReportDefinition) {
            return 'Fetching report definition...';
        }
    }

    handleDismissWarning() {
        this.setState({ warningDismissed: true });
    }

    renderWarning() {
        const warningMsg = this.getWarningMsg();
        if(warningMsg && !this.state.warningDismissed) {
            return <Alert bsStyle="warning" onDismiss={this.handleDismissWarning.bind(this)}>
                { warningMsg }
            </Alert>
        }
    }

    render() {
        return <div>
            { this.renderWarning() }
            <Field label="Transform Type" name="type" component={FormGroupField} inputComponent="select" validate={validateTransformType}>
                <option value="" disabled></option>
                { transformDefinitionKeys.map((key) => {
                    const transformDefinition = transformDefinitions.get(key);
                    const label = transformDefinition && transformDefinition.label;
                    return <option key={key} value={key}>{label}</option>
                })}
            </Field>
            { createParamFields(this.props.transformType, this.props.reportColumns) }
        </div>
    }
}

EditReportTransformForm.propTypes = {
    // ownprops
    reportName: PropTypes.string,
    formId: PropTypes.string.isRequired,

    transformType: PropTypes.string,
    reportDefinition: ImmutablePropTypes.map,
    fetchingReportDefinition: PropTypes.bool.isRequired,
    reportDefinitionHasError: PropTypes.bool.isRequired,
    reportColumns: ImmutablePropTypes.list.isRequired,
    findReportDefinitionByName: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
}

export default connect(
    (state, { formValueSelector, reportName }) => {
        return {
            transformType: formValueSelector(state, 'type'),
            reportDefinition: reportName && getReportDefinition(state, reportName),
            fetchingReportDefinition: getFetchingReportDefinition(state, reportName),
            reportDefinitionHasError: getReportDefinitionHasError(state, reportName),
            reportColumns: getReportColumns(state, reportName || '')
        }
    },
    { findReportDefinitionByName, reset, initialize }
)(EditReportTransformForm);
