import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps a pop-up with "position: fixed",
 * positioning it relative to a container div in the normal document flow.
 * 
 */

class FixedPosition extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.showing && !this.props.showing) {
            const $el = this.$el;
            if($el != null) {
                const rect = $el.getBoundingClientRect();
                this.setState({
                    boundingRect: rect
                })
            }
        }
    }

    render() {
        const style = {
            position: 'fixed',
            zIndex: 2
        }

        if(this.state.boundingRect) {
            if(this.props.alignRight) {
                style.right = window.innerWidth - this.state.boundingRect.right;
            } else {
                style.left = this.state.boundingRect.left;
            }

            if(this.props.alignTop) {
                style.top = this.state.boundingRect.top;
            } else {
                style.bottom = window.innerHeight - this.state.boundingRect.bottom;
            }
        }

        return <div ref={(el) => { if(el) this.$el = el; }}>
            { this.props.showing && [
                <div key="cover" className="cover" onClick={this.props.onHide}/>,
                <div key="content" style={style}>
                    { this.props.children }
                </div>
             ] }
        </div> 
    }
}

FixedPosition.propTypes = {
    alignRight: PropTypes.bool,
    alignTop: PropTypes.bool,
    showing: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default FixedPosition;
