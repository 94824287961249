import React from 'react';
import moment from 'moment';

import EditableDesignField from './EditableDesignField';
import RemoveDesignButton from './RemoveDesignButton';

function createColumns(chooseDesignFn) {
    return [{
        Header: 'Name',
        accessor: 'name',
        Cell(rec) {
            const uuid = rec.original.uuid;
            return <EditableDesignField
                componentFn={(value) => createLink(uuid, value, chooseDesignFn)}
                designId={uuid}
                propertyKey="description"/>
        }
    }, {
        Header: 'Owner',
        accessor: 'owner'
    }, {
        Header: 'Creation Date',
        id: 'creationDate',
        accessor: (rec) => formatTimestamp(rec.creationDate)
    }, {
        Header: 'Modification Date',
        id: 'modificationDate',
        accessor: (rec) => formatTimestamp(rec.modificationDate)
    }, {
        Header: 'Version',
        accessor: 'version'
    }, {
        Header: 'Remove',
        id: 'remove',
        width: 50,
        Cell(rec) {
            const uuid = rec.original.uuid;
            return <RemoveDesignButton
                key={uuid}
                uuid={uuid}/>
        }
    }]
}

function formatTimestamp(timestamp) {
    return moment(timestamp).toISOString();
}

function createLink(designId, value, chooseDesignFn) {
    return <a onClick={() => chooseDesignFn(designId)}>{ value }</a>
}

export default createColumns;
