import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable'
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { showModal } from '../../../../../component-lib/actions';
import { MODAL_GALLERY } from '../../gallery/GalleryModal';

/**
 * An input field for rendering "image" type figure-properties
 */

class GalleryPickerComponent extends React.Component {
    constructor() {
        super();
        this.showGallery = this.showGallery.bind(this);
        this.modalResult = this.modalResult.bind(this);
    }

    render() {
        return <div className="figure-input-field-container">
            <div className="icon-btn figure-input-field-addon">
                <FontAwesome name='photo' onClick={this.showGallery.bind(this)}/>
            </div>
            <input className="figure-input-field"
                value={this.props.value}
                onChange={(e) => this.props.onChange(e.target.value)}/>
        </div>
    }

    showGallery() {
        this.props.showModal('designer-modal', this.createModalParams())
    }

    createModalParams() {
        return fromJS({
            title: 'Gallery',
            body: {
                componentId: MODAL_GALLERY,
                params: { }
            },
            values: {
                extensions: this.props.extensions,
                searchTerm: ''
            },
            actions: [
                { label: 'Select', style: 'success', type: 'submit', action: this.modalResult }
            ]
        })
    }

    modalResult(values) {
        const resource = values.resource;
        if(resource != null) {
            this.props.onChange(resource.id);
        }
    }
}

GalleryPickerComponent.propTypes = {
    fieldKey: PropTypes.string.isRequired,
    altIndex: PropTypes.number.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    extensions: PropTypes.array.isRequired,

    // connect
    showModal: PropTypes.func.isRequired
}

export default connect(
    null,
    { showModal }
)(GalleryPickerComponent);
