import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { figureSelectionStateSelector } from '../../helper/selector/selectionSelectors';
import { childMappingSelector, canvasFigureSelector } from '../../helper/selector/canvasFigureConfigSelectors';
import { getFigureName } from '../../helper/figureConfigHelper';
import { moveFigureZDown, moveFigureZUp, moveFigureZToBottom, moveFigureZToTop, selectFigure, selectSecondaryFigure } from '../../actions/DesignerActions';
import DesignerTreeView from '../common/DesignerTreeView';
import FontAwesome from 'react-fontawesome';

class CanvasFigureItemComponent extends React.Component {

    constructor(props) {
        super(props);
        this.renderChildren = this.renderChildren.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextSelected = this.isSelected(nextProps);
        if(nextSelected && !this.isSelected(this.props)) {
            
            if(this.elem != null && this.props.containerElem != null) {
                const viewportElem = this.props.containerElem.parentNode;
                if(viewportElem == null) return;

                const offsetTop = this.elem.offsetTop;
                const diff = offsetTop - viewportElem.scrollTop;
                if(diff < 0 || diff > viewportElem.clientHeight) {
                    // Scroll to the selected figure list item if not visible
                    viewportElem.scrollTo(0, offsetTop);
                }
            }
        }
    }

    render() {
        const { figureName, figureId, designId, selectionState, childIds } = this.props;
        
        const hasChildren = childIds && childIds.size > 0;

        return <div ref={(elem) => this.elem = elem}>
            <DesignerTreeView 
                nodeId={figureId}
                label={figureName || 'New Figure'}
                labelAddOn={this.renderButtons()}
                selectionState={selectionState}
                defaultCollapsed
                onClick={(e) => {
                    if(e.shiftKey) {
                        this.props.selectSecondaryFigure(designId, figureId, 'canvas');
                    } else {
                        this.props.selectFigure(designId, figureId, [], 'canvas')
                    }
                }}
                createChildren={ hasChildren ? this.renderChildren : undefined }/>
        </div>
    }

    renderButtons() {
        const figureId = this.props.figureId;
        const designId = this.props.designId;
        return [
            <FontAwesome className="treeview-child-icon-btn" key="btn-double-up" name="angle-double-up" onClick={() => this.props.moveFigureZToTop(figureId, designId)}/>,
            <FontAwesome className="treeview-child-icon-btn" key="btn-double-down" name="angle-double-down" onClick={() => this.props.moveFigureZToBottom(figureId, designId)}/>,
            <FontAwesome className="treeview-child-icon-btn" key="btn-up" name="angle-up" onClick={() => this.props.moveFigureZUp(figureId, designId)}/>,
            <FontAwesome className="treeview-child-icon-btn" key="btn-down" name="angle-down" onClick={() => this.props.moveFigureZDown(figureId, designId)}/>
        ]
    }

    renderChildren() {
        const childIds = this.props.childIds;
        const designId = this.props.designId;
        const containerElem = this.props.containerElem;

        return childIds.reverse().map((figureId) => {
            return <CanvasFigureItemComponentConnected
                containerElem={containerElem}
                isChild={true} 
                figureId={figureId}
                key={figureId}
                designId={designId}/>
        });
    }

    isSelected(nextProps) {
        return nextProps
            ? nextProps.selectionState === 'primary' || nextProps.selectionState === 'child'
            : false;
    }
}

CanvasFigureItemComponent.propTypes = {
    // ownprops
    containerElem: PropTypes.any,
    isChild: PropTypes.bool,
    figureId: PropTypes.string.isRequired,
    designId: PropTypes.string.isRequired,

    figureName: PropTypes.string,
    childIds: PropTypes.object.isRequired,
    selectionState: PropTypes.string.isRequired,
    selectFigure: PropTypes.func.isRequired,
    selectSecondaryFigure: PropTypes.func.isRequired,
    moveFigureZDown: PropTypes.func.isRequired,
    moveFigureZUp: PropTypes.func.isRequired,
    moveFigureZToBottom: PropTypes.func.isRequired,
    moveFigureZToTop: PropTypes.func.isRequired
}

const CanvasFigureItemComponentConnected = connect(
    (state, { figureId, designId }) => {
        const figure = canvasFigureSelector(state, designId)(figureId);
        return {
            figureName: getFigureName(figure),
            childIds: childMappingSelector(state, designId).get(figureId),
            selectionState: figureSelectionStateSelector(state, designId, figureId)
        }
    },
{ moveFigureZDown, moveFigureZUp, moveFigureZToBottom, moveFigureZToTop, selectFigure, selectSecondaryFigure }
)(CanvasFigureItemComponent);

export default CanvasFigureItemComponentConnected;
