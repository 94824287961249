import React from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-bootstrap-autosuggest';

/**
 * A redux-form field wrapper for Autosuggest
 */

export default function AutoSuggestField(props) {
    return <AutoSuggest
        {...props.input}
        datalist={props.datalist}
        placeholder={props.placeholder}/>
}

AutoSuggestField.propTypes = {
    input: PropTypes.object.isRequired,
    datalist: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired
}
