import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { getDesignPropertyValue, getFetching } from '../../helper/selector/designListSelector';
import { requestUpdateDesignProperty } from '../../designlist/actions'
import EditableField from './EditableField';

/**
 * An editable field in the design list.
 * Shows a loading symbol when the new value is being submitted to the server.
 */

export class EditableDesignField extends React.Component {
    
    render() {
        return this.props.fetching
            ? this.renderLoading()
            : this.renderEditableField();
    }

    renderLoading() {
        return <FontAwesome spin name="circle-o-notch"/>
    }

    renderEditableField() {
        const { designId, propertyKey } = this.props;
        const fieldKey = getFieldKey(designId, propertyKey);

        return <EditableField 
            fieldKey={fieldKey}
            componentFn={this.props.componentFn}
            value={this.props.value}
            saveValue={this.props.requestUpdateDesignProperty.bind(this, fieldKey) }
            />
    }
}

function getFieldKey(designId, propertyKey) {
    return {
        name: `${designId}_${propertyKey}`,
        designId,
        propertyKey
    }
}

EditableDesignField.propTypes = {
    // ownprops
    designId: PropTypes.string.isRequired,
    propertyKey: PropTypes.string.isRequired,
    componentFn: PropTypes.func,

    fetching: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    requestUpdateDesignProperty: PropTypes.func.isRequired
}

export default connect(
    (state, { designId, propertyKey }) => {
        const fieldKey = getFieldKey(designId, propertyKey);
        return {
            fetching: getFetching(state, fieldKey.name),
            value: getDesignPropertyValue(state, designId, propertyKey)
        }
    },
    { requestUpdateDesignProperty }
)(EditableDesignField);
