
export function validateTransformType(value) {
    return !value && 'No report type selected';
}

export function validateField(param) {
    const optional = param && param.optional;
    return (value) => !optional && !value && 'Required';
}

export function validateFieldArray(param) {
    const validateItem = validateField(param.item);
    return (values) => {
        let error;
        if(!values || values.length === 0) {
            error = 'Must contain at least one field';
            
        } else {
            for(let value of values) {
                const valueError = validateItem(value);
                if(valueError) {
                    error = valueError;
                }
            }
        }
        return error;
    }
}
