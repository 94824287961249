import createCachedSelector from 're-reselect';
import { List } from 'immutable';

export function getReportDefinition(state, reportName) {
    if(!reportName) return;
    return state.designer.getIn(['reportDefinitions', reportName, 'value']);
}

export function getFetchingReportDefinition(state, reportName) {
    if(!reportName) return false;
    return state.designer.getIn(['reportDefinitions', reportName, 'fetching'], false);
}

export function getReportDefinitionHasError(state, reportName) {
    if(!reportName) return false;
    return state.designer.getIn(['reportDefinitions', reportName, 'error'], false);
}

// extracts column names from the report definition
export const getReportColumns = createCachedSelector(
    getReportDefinition,
    (reportDefinition) => {
        const empty = List();
        if(!reportDefinition) return empty;
        return reportDefinition
            .get('models', empty)
            .flatMap((model) => model.get('fields', empty))
            .map((field) => field.get('name'));
    }
)((state, reportName) => reportName);
