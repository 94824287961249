import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { updateSelectedInputFieldValue, updateSelectedDataFieldValue } from '../../actions/DesignerActions';
import { ValidatorType } from '../../helper/validator';
import { createPropValidator } from '../../helper/inputFieldValidator';
import ColorInputComponent from './input/ColorInputComponent';
import ImageInputComponent from './input/ImageInputComponent';
import TextInputComponent from './input/TextInputComponent';
import DrilldownInputComponent from './input/DrilldownInputComponent';
import PdfInputComponent from './input/PdfInputComponent';

import {
    getAltConfigPath,
    createPropDataSelector,
    createPropInputSelector,
    createPropResultSelector,
    figurePathsSelector } from '../../helper/selector/selectionSelectors';

class FigureInputComponent extends React.PureComponent {

    UNSAFE_componentWillMount() {
        this.errorMessage = this.props.validate(this.getValue());
    }

    UNSAFE_componentWillReceiveProps(props) {
        // the selection was changed
        if(this.props.configPaths !== props.configPaths) {
            this.errorMessage = this.props.validate(this.getValue());
            return;
        }

        if(this.props.input !== props.input
            && props.input != null) {

            // Update data value when the input changed, and validation passed
            this.errorMessage = props.validate(props.input);
            if(this.errorMessage == null) {
                const resultPaths = this.getResultPaths();
                this.props.updateSelectedDataFieldValue(resultPaths, props.fieldKey, props.input);
            }
        }

        if(this.props.data !== props.data
            && props.data != null) {

            // Update input value when the figure config is changed
            const resultPaths = this.getResultPaths();
            this.props.updateSelectedInputFieldValue(resultPaths, props.fieldKey, props.data);
        }
    }

    getValue() {
        return this.props.input != null ? this.props.input : this.props.data;
    }

    render() {
        const componentProps = {
            value: this.getValue(),
            onChange: this.handleValueChanged.bind(this),
            fieldKey: this.props.fieldKey,
            altIndex: this.props.altIndex
        }

        const inputComponent = this.props.componentFactory
            ? this.props.componentFactory(componentProps)
            : this.inputComponentFactory(componentProps);

        const errorMessage = this.errorMessage;
        return <div className={errorMessage ? 'figure-input-invalid' : ''}>
            { inputComponent }
            <span>{ errorMessage }</span>
            { this.renderResults() }
        </div>
    }

    renderResults() {
        if(!this.props.result
            || !this.props.result.size
            || this.props.result.get(0) == this.getValue()) return [];
        
        return (
            <div className="figure-input-results-list">
                <div className="figure-input-result">{ '' + this.props.result.get(0) }</div>
            </div>
        );
    }

    inputComponentFactory(componentProps) {
        switch(this.props.validator && this.props.validator.type) {
            case ValidatorType.color:
                return <ColorInputComponent {...componentProps}/>
            case ValidatorType.image:
                return <ImageInputComponent {...componentProps}/>
            case ValidatorType.drilldown:
                return <DrilldownInputComponent {...componentProps}/>
            case ValidatorType.pdf:
                return <PdfInputComponent {...componentProps}/>
            default:
                return <TextInputComponent {...componentProps}/>
        }
    }

    handleValueChanged(value) {
        const resultPaths = this.getResultPaths();
        this.props.updateSelectedInputFieldValue(resultPaths, this.props.fieldKey, value);
    }

    getResultPaths() {
        const altPath = getAltConfigPath(this.props.altIndex);
        return this.props.configPaths.map((configPath) => configPath.concat(altPath));
    }
}

FigureInputComponent.propTypes = {
    designId: PropTypes.string.isRequired,
    fieldKey: PropTypes.string.isRequired,
    altIndex: PropTypes.number.isRequired,
    componentFactory: PropTypes.func,
    
    // connect
    data: PropTypes.any,
    input: PropTypes.any,
    result: PropTypes.any,
    configPaths: PropTypes.instanceOf(List),
    updateSelectedDataFieldValue: PropTypes.func.isRequired,
    updateSelectedInputFieldValue: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired
}

export default connect(
    (state, { fieldKey, altIndex }) => {
        const validate = createPropValidator(fieldKey);
        const dataSelector = createPropDataSelector(fieldKey, altIndex);
        const inputSelector = createPropInputSelector(fieldKey, altIndex);
        const resultSelector = createPropResultSelector(fieldKey, altIndex);
        
        return (state, { designId }) => {
            const data = dataSelector(state, designId);
            const input = inputSelector(state, designId);
            const result = resultSelector(state, designId);
            const configPaths = figurePathsSelector(state, designId);

            return {
                input,
                data,
                result,
                configPaths,
                validate
            }
        }
    },
    { updateSelectedInputFieldValue, updateSelectedDataFieldValue }
)(FigureInputComponent);
