import { compile, compileCondition, isDynamicExpression } from './eval/evaluator';
import { fieldFromFigureKey } from './DesignerHelper';

export function createPropValidator(propKey) {
    const field = fieldFromFigureKey(propKey);
    const validator = field && field.get('validator');
    const isCondition = propKey === 'condition';

    return (propValue) => {
        try {
            
            const expression = isCondition
                ? compileCondition(propKey, propValue)
                : compile(propKey, propValue);

            // don't validate dynamic expressions.
            // will be validated in computedPropsReducers anyway.
            if(isDynamicExpression(expression)) return;
            
            if(validator) {
                validator.validate(propValue);
            }
            
        } catch(e) {
            return '' + e;
        }
    }
}
