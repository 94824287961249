import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'

class Master extends React.Component {

  renderHeaders(columns) {
    const ths = columns.map((column) => <th>{column.get('name')}</th>)
    return <tr>{ths}</tr>
  }

  renderRow(columnNames, item) {
    let className = ''
    let onDoubleClick = null
    if (this.props.selectFn) {
      className = 'hover-clickable'
      onDoubleClick = () => this.props.selectFn(item)
    }
    const tds = columnNames.map((cn) => <td>{item.get(cn)}</td>)
    return <tr className={className} onDoubleClick={onDoubleClick}>
      {tds}
    </tr>
  }

  renderRows(columns, items) {
    const columnNames = columns.map((c) => c.get('name'))
    return items.map((item) => this.renderRow(columnNames, item))
  }

  renderStatus(status) {
    if (status == 'done' || status === undefined) {
      return null
    }
    return <div><label>{status}</label></div>
  }

  render() {
    const { columns, items, status } = this.props
    return <div>
      <table className='table table-striped table-hover'>
        <thead>{this.renderHeaders(columns)}</thead>
        <tbody>{this.renderRows(columns, items)}</tbody>
      </table>
      {this.renderStatus(status)}
    </div>
  }
}

Master.propTypes = {
  columns: PropTypes.instanceOf(List).isRequired,
  items: PropTypes.instanceOf(List).isRequired,
  status: PropTypes.string,
  selectFn: PropTypes.func
}

export default Master
