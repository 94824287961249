import React from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS} from 'immutable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as PartnerActions from '../actions/PartnerActions'
import Selector from '../../../common/view/components/Selector'
import Master from '../../../common/view/components/Master'
import {newId, initialValues} from '../../../util/form'

const Datasources = {
  PARTNER: 'PARTNER'
}

const PARTNER_SELECTOR_CONFIG = fromJS({
  datasources: {
    'main': {
      // the main datasource
      datasource: Datasources.PARTNER,
      set: {
        no: 'no'
      }
    }
  },
  layout: {
    columns: 2
  },
  fields: [
  {
    name: 'no',
    type: 'text'
    //validations: [ifNullNotNull('password')]
  }]
})

const PARTNER_MASTER_CONFIG = fromJS({
  columns: [
   {
      name: 'no',
      type: 'text'
   },
   {
     name: 'name',
     type: 'text'
   }
  ]
})

class PartnerView extends React.Component {

  constructor() {
    super();
    const selectorId = newId('partner-selector_')
    const masterId = newId('partner-master_')
    const datasourcesConfig = PARTNER_SELECTOR_CONFIG.get('datasources')
    this.state = {
      selectorId, masterId,
      selectorProps: {
        formId: selectorId,
        layout: PARTNER_SELECTOR_CONFIG.get('layout'),
        fields: PARTNER_SELECTOR_CONFIG.get('fields'),
        initialValues: initialValues(datasourcesConfig.get('main'), this.props.initialDatasourceValues),
        datasources: datasourcesConfig
      }
    }
  }

  renderFooter() {
    const {cancelFn} = this.props
    if (cancelFn === undefined) {
      return null
    }
    //<input type="button" onClick={selectFn} value='Select'/>
    return <div>
      <input type="button" onClick={cancelFn} value='Cancel'/>
    </div>
  }

  render() {
    const {selectFn, datasource} = this.props
    const masterData = datasource.getIn([this.state.selectorId, 'main'], Map())
    const masterProps = {
      columns: PARTNER_MASTER_CONFIG.get('columns'),
      items: masterData.get('items', List()),
      status: masterData.get('status'),
      selectFn
    }
    return <div>
      <h3>Partner:</h3>
      <Selector {...this.state.selectorProps} />
      <Master {...masterProps}/>
      {this.renderFooter()}
    </div>
  }
}

PartnerView.propTypes = {
  datasource: PropTypes.instanceOf(Map).isRequired,
  selectFn: PropTypes.func,
  cancelFn: PropTypes.func,
  initialDatasourceValues: PropTypes.instanceOf(Map)
}

export default connect(
  (state) => state,
  (dispatch) => bindActionCreators(PartnerActions, dispatch)
)(PartnerView);
