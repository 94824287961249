import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import _ from 'lodash';

export default function HtmlTextModalContent(props) {
    {/* dangerouslySetInnerHtml: allow the body of the modal to contain html markup (bold, italic, etc. ) */}
    return <div dangerouslySetInnerHTML={{ __html: formatBodyHtmlEscaped(props.template, props.args) }} />
}

HtmlTextModalContent.propTypes = {
    template: PropTypes.string.isRequired,
    args: ImmutablePropTypes.listOf(PropTypes.string)
}

/**
 * @param {string[]} body 
 */
function formatBodyHtmlEscaped(template, args) {
    if(!args) return template;
    return template.replace(/{(\d+)}/g, function(match, num) {
        return _.escape(args.get(num));
    });
}

export const CONTENT_TYPE_TEXT_HTML = 'text-html';
