import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

import { getFetching, createDesignSelector } from '../../helper/selector/designListSelector';
import { requestRemoveDesign } from '../../designlist/actions';
import { showModal } from '../../../../component-lib/actions';

function RemoveButton(props) {
    return <Button bsStyle="danger" 
                disabled={props.fetching} 
                onClick={() => props.showModal('designlist-modal', getModalParams(props.uuid, props.name, props.requestRemoveDesign))}>
                <FontAwesome name="times"/>
            </Button>
}

// show the confirm modal
function getModalParams(uuid, name, requestRemoveDesign) {
    return fromJS({
        title: 'Confirm Remove',
        body: {
            componentId: 'text-html',
            params: {
                template: 'Are you sure you want to remove the design \'<b>{0}</b>\'?',
                args: [name]
            }
        },
        actions: [
            { label: 'Cancel' },
            { label: 'Confirm', style: 'danger', action: () => requestRemoveDesign(uuid) }
        ]
    });
}

RemoveButton.propTypes = {
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    
    showModal: PropTypes.func.isRequired,
    requestRemoveDesign: PropTypes.func.isRequired
}

export default connect(
    (state, { uuid }) => {
        const selectDesign = createDesignSelector(uuid);
        return (state) => ({
            name: selectDesign(state).get('description'),
            fetching: getFetching(state, uuid)
        })
    }, 
    { showModal, requestRemoveDesign }
)(RemoveButton);
