import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';

import { getColorPickerVisibleById } from '../../helper/selector/selectors';
import { setColorPickerVisible } from '../../common/actions';
import FixedPosition from './FixedPosition';

class DesignerColorPicker extends React.Component {

    componentWillUnmount() {
        this.hidePicker();
    }

    render() {
        return <div className={this.props.className}>
            <div className="color-picker-min" onClick={this.showPicker.bind(this)}>
                <div style={{ borderRadius: '3px', height: '100%', backgroundColor: this.props.value }}/>
            </div>
            <FixedPosition showing={this.props.showing} onHide={this.hidePicker.bind(this)} alignRight={this.props.alignRight} alignTop={true}>
                <SketchPicker color={this.props.value} onChangeComplete={this.handleColorChanged.bind(this)}/>
            </FixedPosition>
        </div>
    }

    showPicker() {
        this.props.setColorPickerVisible(this.props.colorPickerId, true);
    }

    hidePicker() {
        this.props.setColorPickerVisible(this.props.colorPickerId, false);
    }

    handleColorChanged(value) {
        value = value.hex;
        if(this.props.onChange) {
            this.props.onChange(value)
        }
    }
}

DesignerColorPicker.propTypes = {
    // ownprops
    value: PropTypes.string,
    colorPickerId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    alignRight: PropTypes.bool,

    showing: PropTypes.bool.isRequired,
    setColorPickerVisible: PropTypes.func.isRequired
}

export default connect(
    (state, { colorPickerId }) => ({
        showing: getColorPickerVisibleById(state, colorPickerId)
    }),
    { setColorPickerVisible }
)(DesignerColorPicker);
