import React from 'react';
import PropTypes from 'prop-types';

/**
 * The default figure-property input field. Just renders a single text field
 */

export default function TextInputComponent(props) {
    let text = props.value != null ? props.value : '';
    return <div className="figure-input-field-container">
        <input className="figure-input-field" type="text" value={text} onChange={(e) => props.onChange(e.target.value)}/>
    </div>
}

TextInputComponent.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired
}
