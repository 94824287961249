import Imm from 'immutable';
import { createSelector } from 'reselect';
import { figureParentsSelector } from './figuresConfigSelector';
import {
    selectionTypeSelector,
    primaryFigureSelector,
    secondaryFiguresSelector,
    figureIdentifiersSelector } from './selectionSelectors';

export const canAssignToParent = createSelector(
    selectionTypeSelector,
    primaryFigureSelector,
    secondaryFiguresSelector,
    (selectionType, primaryFigure, secondaryFigures) => {
        return selectionType === 'canvas' && primaryFigure != null
            && secondaryFigures.size > 0
            && secondaryFigures.some((secondaryFig) => !primaryFigure.get('children', Imm.List()).contains(secondaryFig))
    }
)

export const canUnassignFromParent = createSelector(
    selectionTypeSelector,
    figureIdentifiersSelector,
    figureParentsSelector,
    (figureType, allIdentifiers, parentsSelector) => {
        return figureType === 'canvas' && allIdentifiers.size > 0 && allIdentifiers.every((identifier) => {
            const parents = parentsSelector(identifier);
            return parents && parents.size > 1;
        });
    }
);
