import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import CanvasFigureItem from './CanvasFigureListItem';
import { childMappingSelector } from '../../helper/selector/canvasFigureConfigSelectors';
import { getCurrentDesignId } from '../../helper/selector/selectors';

class CanvasFigureList extends React.Component {

    constructor(props) {
        super(props);
        this.handleRefChanged = this.handleRefChanged.bind(this);
    }

    render() {
        const figureIds = this.props.figureIds;
        const designId = this.props.designId;

        return <div className="designer-pane-content" ref={this.handleRefChanged}>
            { figureIds.reverse().map((figureIds) => {
                return <CanvasFigureItem
                    containerElem={this.elem}
                    key={figureIds} 
                    figureId={figureIds}
                    designId={designId}/>
            }) }
        </div>
    }

    handleRefChanged(elem) {
        this.elem = elem;
        this.forceUpdate();
    }
}

CanvasFigureList.propTypes = {
    designId: PropTypes.string.isRequired,
    figureIds: PropTypes.instanceOf(List).isRequired
}

export default connect(
    (state) => {
        const designId = getCurrentDesignId(state);
        return {
            designId,
            figureIds: childMappingSelector(state, designId).get('root')
        }
    }
)(CanvasFigureList);
