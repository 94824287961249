import Imm from 'immutable';
import { createSelector } from 'reselect';

export const getFiguresManifest = (state) => state.designer.get('figures-manifest');

export const getGalleryPath = (state) => state.designer.getIn(['gallery', 'path']);

export function param(paramName) {
    return (state, params) => params[paramName];
}

function filter(manifestItem, extensions, searchTerm) {
    return manifestItem.directory || (
        manifestItem.fileName.toLowerCase()
            .startsWith(searchTerm.toLowerCase()) && extensions.has(manifestItem.extension));
}

export const createFilteredResourcesSelector = () =>
    createSelector(
        getFiguresManifest,
        param('extensions'),
        param('searchTerm'),
        (figuresManifest, extensions, searchTerm) => {
            if(figuresManifest == null) return { resources: [], count: 0 };
            
            extensions = new Set(extensions);

            const stack = [];
            const resources = [];
            for(let manifestItem of figuresManifest) {
                stack.push([ manifestItem, resources ]);
            }
    
            let count = 0;
            let visited = [];
            while(stack.length) {
                const [manifestItem, results] = stack[stack.length - 1];

                if(filter(manifestItem, extensions, searchTerm)) {
                    if(!manifestItem.directory) {
                        count += 1;
                    }
                    
                    if(manifestItem.sub.length) {
                        if(visited.length) { 
                            const [id, sub] = visited[visited.length - 1];
                            if(id === manifestItem.id) {
                                stack.pop();
                                visited.pop();
                                if(sub.length) {
                                    results.push(Object.assign({}, manifestItem, { sub }));
                                }
                                continue;
                            }
                        }

                        const sub = [];
                        visited.push([manifestItem.id, sub]);
                        for(let subItem of manifestItem.sub) {
                            stack.push([subItem, sub]);
                        }

                    } else {
                        results.push(manifestItem);
                        stack.pop();
                    }

                } else {
                    stack.pop();
                }
            }

            return { resources, count };
        });

export const createResourcesSelector = () => createSelector(
    createFilteredResourcesSelector(),
    getGalleryPath,
    (resources, path) => {
        let subPath = Imm.List();
        let subResources = resources.resources;
        const count = resources.count;

        if(path != null) {
            for(let resourceId of path) {
                const res = subResources.find((r) => r.id === resourceId);
                if(res == null) break;

                subResources = res.sub;
                subPath = subPath.push(resourceId);
            }
        } else {
            while(subResources.length === 1 && subResources[0].directory) {
                const res = subResources[0];
                subPath = subPath.push(res.id);
                subResources = res.sub;
            }
        }

        return {
            resources: subResources,
            path: subPath,
            count
        }
    });
