import React from 'react';
import DesignerDetails from './figureConfig/DesignerDetails';
import CanvasFigureList from './canvasFigureList/CanvasFigureList';
import SplitPane from 'react-split-pane';
import DesignerPane from './DesignerPane';

export default function RightPane(props) {
    return <SplitPane primary="second" split="horizontal" defaultSize={200} paneStyle={{width: '100%', overflow: 'auto'}}>
        <DesignerPane title="Details">
            <DesignerDetails {...props}/>
        </DesignerPane>
        <DesignerPane title="Canvas Figures">
            <CanvasFigureList/>
        </DesignerPane>
    </SplitPane>
}
