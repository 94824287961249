import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button, FormGroup } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import 'react-table/react-table.css';

import createColumns from './Columns';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { fetchDesignList } from '../../designlist/actions';
import { getDesignList } from '../../helper/selector/designListSelector';

class DesignListTable extends React.Component {

    UNSAFE_componentWillMount() {
        this.props.fetchDesignList();
    }

    render() {
        if(!this.props.designList)
            return <LoadingPlaceholder message="Fetching Designs..."/>

        const data = getTableData(this.props.designList);
        const columns = createColumns(this.props.chooseDesign);

        return <FormGroup>
            <Button style={{ marginBottom: 3 }} onClick={this.props.fetchDesignList}>
                <FontAwesome name="refresh"/>
            </Button>
            <ReactTable
                columns={columns}
                data={data}/>
        </FormGroup>
    }
}

function getTableData(designList) {
    return designList.map((design) => ({
        name: design.get('description'),
        owner: design.get('owner'),
        creationDate: design.get('creationDate'),
        modificationDate: design.get('modificationDate'),
        version: design.get('version'),
        uuid: design.get('uniqueIdentifier')
    })).toArray();
}

DesignListTable.propTypes = {
    chooseDesign: PropTypes.func.isRequired,
    designList: ImmutablePropTypes.list,
    fetchDesignList: PropTypes.func.isRequired
}

export default connect(
    (state) => ({
        designList: getDesignList(state)
    }),
    { fetchDesignList }
)(DesignListTable);
