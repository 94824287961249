import React from 'react';
import SplitPane from 'react-split-pane';
import DesignerPalette from './palette/DesignerPalette';
import DesignerDataSources from './datasources/DesignerDataSources';
import DesignerPane from './DesignerPane';

export default function LeftPane(props) {
    return <SplitPane primary="second" split="horizontal" defaultSize={'50%'} paneStyle={{width: '100%', overflow: 'auto'}}>
        <DesignerPane title="Palette">
            <DesignerPalette key='designer-palette'/>
        </DesignerPane>
        <DesignerPane title="Datasources">
            <DesignerDataSources key='designer-datasources' {...props} mode='Design' />
        </DesignerPane>
    </SplitPane>
}
