import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar, NavItem, NavDropdown } from 'react-bootstrap';
import {IndexLinkContainer} from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import FontAwesome from 'react-fontawesome';

function Navigation(props) {
    return (
        <Navbar>
            <Nav className="mr-auto">
                <NavDropdown title={<FontAwesome name="th"/>} id="basic-nav-dropdown">
                    <IndexLinkContainer to={{ pathname: '/enterprise-designer'}} onlyActiveOnIndex={false}>
                        <NavDropdown.Item>Designer</NavDropdown.Item>
                    </IndexLinkContainer>
                    <IndexLinkContainer to={{ pathname: '/partner'}} onlyActiveOnIndex={false}>
                        <NavDropdown.Item>Partner</NavDropdown.Item>
                    </IndexLinkContainer>
                    <IndexLinkContainer to={{ pathname: '/purchase'}} onlyActiveOnIndex={false}>
                        <NavDropdown.Item>Purchase</NavDropdown.Item>
                    </IndexLinkContainer>
                </NavDropdown>
            </Nav>
            <Nav pullRight>
                <NavItem eventKey={6} href="#" onClick={() => {
                    props.push('/login');
                }}><b>Sign out</b></NavItem>
            </Nav>
        </Navbar>);
}

Navigation.propTypes = {
    push: PropTypes.func.isRequired
};

export default connect(undefined, { push })(Navigation);
