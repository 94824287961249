import React from 'react';
import PropTypes from 'prop-types';
import Imm from 'immutable';
import { connect } from 'react-redux';
import { cloneFigureConfig, setCloneFigureNameValue } from '../../actions/DesignerActions';
import { primaryFigureSelector } from '../../helper/selector/selectionSelectors';

/**
 * "Clone figure" form
 */

class CloneFigureConfigDialog extends React.Component {

    handleCloneFigureConfig() {
        this.props.setCloneFigureNameValue('');
        this.props.cloneFigureConfig(this.props.designId, this.props.figure, this.props.newFigureName);
    }

    render() {
        const figName = this.props.newFigureName;
        return <div className="clone-figure-config">
            <button 
                disabled={!figName} 
                onClick={() => this.handleCloneFigureConfig()}> Clone Descriptor </button>
            <input type="text" value={figName} placeholder="figure name" onChange={(e) => this.props.setCloneFigureNameValue(e.target.value)}/>
        </div>
    }
}

CloneFigureConfigDialog.propTypes = {
    // connect
    designId: PropTypes.string.isRequired,
    figure: PropTypes.instanceOf(Imm.Map).isRequired,
    newFigureName: PropTypes.string,
    setCloneFigureNameValue: PropTypes.func.isRequired,
    cloneFigureConfig: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => {
        return {
            figure: primaryFigureSelector(state, designId),
            newFigureName: state.designer.get('cloneFigureName')
        }
    },
    { cloneFigureConfig, setCloneFigureNameValue }
)(CloneFigureConfigDialog);
