import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable'

import { StringValidator } from '../../helper/validator';
import FigureConfigurationUi from './FigureConfigurationUi'

import { figurePathsSelector, selectionTypeSelector, getAltConfigPath, createPropKeysSelector } from '../../helper/selector/selectionSelectors';
import * as DesignerHelper from '../../helper/DesignerHelper'
import * as designerActions from '../../actions/DesignerActions';

const OVERRIDDEN_BY = DesignerHelper.CONFIGURABLE_FIGURE_PROPS.reduce(
  (r, prop) => {
    if (prop.has('into')) {
      return r.set(prop.get('into'), prop.get('key'))
    }
    return r
  }, Map())

class FigureConfiguration extends React.Component {

  constructor() {
    super();

    // bind callbacks to the current instance
    this.removeConfiguration = this.removeConfiguration.bind(this);
    this.moveFigureConfigDown = this.moveFigureConfigDown.bind(this);
    this.moveFigureConfigUp = this.moveFigureConfigUp.bind(this);
    this.showFigureConfigDetails = this.showFigureConfigDetails.bind(this);
    this.shouldShowFigurePropKey = this.shouldShowFigurePropKey.bind(this);
    this.prop2field = this.prop2field.bind(this);
  }


  removeConfiguration() {
    const altPath = getAltConfigPath(this.props.altIndex);
    for (let configPath of this.props.configPaths) {
      this.props.removeFigureConfig(configPath.concat(altPath));
    }
  }

  showFigureConfigDetails() {
    const type = this.props.selectionType;
    const altIndex = this.props.altIndex;
    this.props.showFigureConfigDetails(this.props.designId, { type, altIndex });
  }

  moveFigureConfigDown() {
    const altPath = getAltConfigPath(this.props.altIndex);
    for (let configPath of this.props.configPaths) {
      this.props.moveFigureConfigDown(configPath.concat(altPath));
    }
  }

  moveFigureConfigUp() {
    const altPath = getAltConfigPath(this.props.altIndex);    
    for (let configPath of this.props.configPaths) {
      this.props.moveFigureConfigUp(configPath.concat(altPath));
    }
  }

  shouldShowFigurePropKey(propKey) {
    if (!propKey) return false;

    // don't show figure props if will be overriden
    const overridePropKey = OVERRIDDEN_BY.get(propKey);
    return typeof (overridePropKey) === 'undefined';
  }

  prop2field(cp) {
    const key = cp.get('key');
    const group = cp.get('group');
    const order = cp.get('order');
    return {
      key,
      group,
      order,
      label: cp.get('label', cp.get('key')),
      type: this.fieldType(cp.get('key')),
      validator: cp.get('validator')
    }
  }

  render() {
    let configurableProps = this.props.propKeys
      .map(DesignerHelper.fieldFromFigureKey)
      .filter(this.shouldShowFigurePropKey);

    let removeConfiguration, moveFigureConfigDown, moveFigureConfigUp;
    if (this.isAltConfig()) {
      removeConfiguration = this.removeConfiguration
      moveFigureConfigDown = this.moveFigureConfigDown
      moveFigureConfigUp = this.moveFigureConfigUp
      configurableProps = configurableProps.unshift(Map({ key: 'condition', validator: new StringValidator() }))
    }

    let fields = configurableProps.map(this.prop2field)
    return <FigureConfigurationUi
      fields={fields}
      altIndex={this.props.altIndex}
      designId={this.props.designId}
      showFigureConfigDetails={this.showFigureConfigDetails}
      removeConfiguration={removeConfiguration}
      moveFigureConfigDown={moveFigureConfigDown}
      moveFigureConfigUp={moveFigureConfigUp} />
  }

  isAltConfig() {
    return this.props.altIndex >= 0;
  }

  // handleChange(key, value) {
  //   switch (key) {
  //     case 'text-template': return this.updateConfig(() =>
  //        List().push(DesignerHelper.diffSet('text', value))
  //              .push(DesignerHelper.diffSet('text-template', value)))
  //     default: return this.updateConfig(() =>
  //        List().push(DesignerHelper.diffSet(key, value)))
  //   }
  // },

  fieldType(k) {
    switch (k) {
      case 'text-template': return 'textArea'
      case 'visible': return 'checkbox'
      case 'path': return 'image'
      default: return 'input'
    }
  }
}

FigureConfiguration.propTypes = {
  designId: PropTypes.string.isRequired,
  altIndex: PropTypes.number.isRequired,

  // connect
  configPaths: PropTypes.instanceOf(List).isRequired,
  selectionType: PropTypes.string.isRequired,
  propKeys: PropTypes.instanceOf(List).isRequired,
  
  changeFigureConfig: PropTypes.func.isRequired,
  removeFigureConfig: PropTypes.func.isRequired,
  showFigureConfigDetails: PropTypes.func.isRequired,
  moveFigureConfigDown: PropTypes.func.isRequired,
  moveFigureConfigUp: PropTypes.func.isRequired
}

export default connect(
  (state, { altIndex }) => {
    const propKeysSelector = createPropKeysSelector(altIndex);
    return (state, { designId }) => ({
      configPaths: figurePathsSelector(state, designId),
      propKeys: propKeysSelector(state, designId),
      selectionType: selectionTypeSelector(state, designId)
    })
  },
  { ...designerActions }
)(FigureConfiguration)
