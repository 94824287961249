import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { getDesignList, getFetching } from '../../../helper/selector/designListSelector';
import { fetchDesignList } from '../../../designlist/actions'

/**
 * An figure property input component, to render the "drill-down" property.
 */

class DrilldownInputComponent extends React.Component {

    UNSAFE_componentWillMount() {
        if(this.props.designList == null && !this.props.fetchingDesignList) {
            this.props.fetchDesignList();
        }
    }

    render() {
        let designId, params;
        if(this.props.value) {
            designId = this.props.value.get('designId');
            params = this.props.value.get('params');
        }

        return <div className="figure-input-field-container">
            <select className="figure-input-field" value={designId || ''} onChange={this.handleDesignIdChanged.bind(this)}>
                <option disabled value=""></option>
                { this.props.designList
                    && this.props.designList.map((design) => {
                        const uuid = design.get('uniqueIdentifier');
                        return <option key={uuid} value={uuid}>{ design.get('description') }</option>
                    }
                )}
            </select>
            <input className="figure-input-field-addon"
                placeholder="params"
                value={params || ''}
                onChange={this.handleParamsChanged.bind(this)}/>
        </div>
    }

    handleDesignIdChanged(e) {
        return this.props.onChange(Map({
            designId: e.target.value,
            params: this.props.value && this.props.value.get('params')
        }))
    }

    handleParamsChanged(e) {
        return this.props.onChange(Map({
            designId: this.props.value && this.props.value.get('designId'),
            params: e.target.value
        }));
    }
}

DrilldownInputComponent.propTypes = {
    // own-props
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,

    designList: ImmutablePropTypes.list,
    fetchDesignList: PropTypes.func.isRequired,
    fetchingDesignList: PropTypes.bool.isRequired
}

export default connect(
    (state) => ({
        designList: getDesignList(state),
        fetchingDesignList: getFetching(state, 'designList')
    }),
    { fetchDesignList }
)(DrilldownInputComponent);
