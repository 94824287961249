import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import GalleryView from './GalleryView';

function SearchField(props) {
    return (
    <FormGroup>
        <FormLabel>Search</FormLabel>
        <FormControl {...props.input}/>
    </FormGroup>);
}

SearchField.propTypes = {
    input: PropTypes.object.isRequired
}

export default function Gallery(props) {
    return <div>
        <Field name="searchTerm" component={SearchField}/>
        <Field name="resource" component={GalleryView} {...props}/>
    </div>
}
