import React from 'react'
import PropTypes from 'prop-types';
import { List } from 'immutable';
import FontAwesome from 'react-fontawesome';

import FigureInputComponent from './FigureInputComponent';

class FigureConfigurationUi extends React.Component {

  render() {
    return (
      <div className="figure-configuration">
        {this.renderButtons()}
        {this.props.fields.map((field) => this.getField(field))}
        {this.renderFieldAdd()}
      </div>
    );
  }

  renderButtons() {
    let removeConfig, moveConfigUp, moveConfigDown
    if(this.props.removeConfiguration) {
      removeConfig = <div key="btn-remove" className="icon-btn danger"><FontAwesome name="trash" onClick={this.props.removeConfiguration}/></div>
      moveConfigDown = <div key="btn-move-down" className="icon-btn"><FontAwesome name="angle-down" onClick={this.props.moveFigureConfigDown}/></div>
      moveConfigUp = <div key="btn-move-up" className="icon-btn"><FontAwesome name="angle-up" onClick={this.props.moveFigureConfigUp}/></div>
    }
    const showConfig = <div key="btn-show-config" className="icon-btn"><FontAwesome name="ellipsis-h" onClick={this.props.showFigureConfigDetails}/></div>
    return [removeConfig, showConfig, moveConfigDown, moveConfigUp]
  }

  renderFieldAdd() {
    return null
  }

  getField(field) {
      return <FigureConfigurationField
        key={field.key}
        field={field}
        altIndex={this.props.altIndex}
        designId={this.props.designId}/>
  }
}

FigureConfigurationUi.propTypes = {
  fields: PropTypes.instanceOf(List).isRequired,
  altIndex: PropTypes.number.isRequired,
  designId: PropTypes.string.isRequired,

  showFigureConfigDetails: PropTypes.func.isRequired,
  removeConfiguration: PropTypes.func,
  moveFigureConfigDown: PropTypes.func,
  moveFigureConfigUp: PropTypes.func
}

class FigureConfigurationField extends React.Component {
  
  render() {
    const { validator, key, label, type } = this.props.field
    const altIndex = this.props.altIndex;
    const designId = this.props.designId;

    if (type === 'checkbox') {
      return <div className="figure-configuration-field">
        <label>{label} </label>
        <FigureInputComponent designId={designId} altIndex={altIndex} fieldKey={key}
          validator={validator}
          componentFactory={this.renderCheckboxInput}/>
      </div>
    }

    if (type === 'textArea') {
      return <div className="figure-configuration-field">
        <label style={{verticalAlign: 'top'}}>{label} </label>
        <FigureInputComponent designId={designId} altIndex={altIndex} fieldKey={key}
          validator={validator}
          componentFactory={this.renderTextAreaInput}/>
      </div>
    } else {
      return <div className="figure-configuration-field">
        <label>{label} </label>
        <FigureInputComponent designId={designId} altIndex={altIndex} fieldKey={key}
          validator={validator}/>
      </div>
    }
  }

  renderCheckboxInput({ value, onChange }) {
    return <input checked={!!value}
      type="checkbox"
      onChange={(e) => onChange(e.target.checked)}/>
  }

  renderTextAreaInput({ value, onChange }) {
    return <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}/>
  }
}

FigureConfigurationField.propTypes = {
  field: PropTypes.shape({
    validator: PropTypes.object.isRequired,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  designId: PropTypes.string.isRequired,
  altIndex: PropTypes.number.isRequired
}

export default FigureConfigurationUi
