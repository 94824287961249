import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormCheck, FormText } from 'react-bootstrap';

export default function CheckboxField(props) {
    return <FormGroup>
        <FormCheck
            checked={props.input.value}
            onChange={() => props.input.onChange(!props.input.value)}
            label={props.label}
        />
        { props.meta.error
            && <FormText className="text-muted">{ props.meta.error }</FormText> }
    </FormGroup>
}

CheckboxField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired
}
