import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloneFigureConfigDialog from './CloneFigureConfigDialog';
import CanvasFigureConfigHeader from './CanvasFigureConfigHeader';
import DescriptorFigureConfigHeader from './DescriptorFigureConfigHeader';
import { selectionTypeSelector } from '../../helper/selector/selectionSelectors';

/**
 * Common config options for canvas and descriptor figures
 */

function FigureConfigHeader(props) {
    let header;

    // show different header based on figure-config type
    if(props.selectionType === 'canvas') {
        header = <CanvasFigureConfigHeader designId={props.designId}/>

    } else {
        header = <DescriptorFigureConfigHeader designId={props.designId}/>
    }

    return <div>
        { header }
        <CloneFigureConfigDialog designId={props.designId}/>
    </div>
}

FigureConfigHeader.propTypes = {
    designId: PropTypes.string.isRequired,
    
    // connect
    selectionType: PropTypes.string.isRequired
}

export default connect(
    (state, { designId }) => ({
        selectionType: selectionTypeSelector(state, designId)
    })
)(FigureConfigHeader)
