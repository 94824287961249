import React from 'react'
import PropTypes from 'prop-types'
import DesignerCanvas from './DesignerCanvas'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as DesignerActions from '../actions/DesignerActions'
import * as ComputedPropsActions from '../actions/computedPropsActions';
import { List, Map } from 'immutable'
import CopyToClipboard from 'react-copy-to-clipboard'
import SplitPane from 'react-split-pane';

import RightPane from './RightPane';
import LeftPane from './LeftPane';
import FigureConfigurationDetails from './figureConfig/FigureConfigurationDetails'
import * as DesignerSagaActions from '../datarequest/actions'
import CanvasContextMenu from './CanvasContextMenu';
import DesignerHeader from './header/DesignerHeader';
import BreadcrumbTrail from './header/Breadcrumb';
import DesignerModal from '../../../component-lib/modal/GenericModalComponent';
import modalComponentsMap from './modal/designerModalComponentsMap';
import DatasourcesPoller from './view/DatasourcesPoller';
import DesignerViewPage from './view/DesignerViewPage';
import { monitor } from '../helper/perfMonitor';

let ReactGridLayout = require('react-grid-layout')
require('react-grid-layout/css/styles.css')
require('react-resizable/css/styles.css')
const widthProvider = require('react-grid-layout').WidthProvider
ReactGridLayout = widthProvider(ReactGridLayout)

class EnterpriseDesigner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      designIdInput: 'test-design'
    }
  }

  UNSAFE_componentWillMount() {
    // uncomment to enable performance monitoring
    // monitor();
  }

  render() {
    const designId = this.props.designId
    const designs = this.props.designer.get('designs')
    const design = designs.get(designId)
    let contentDOM
    if (this.props.mode == 'View') {
      contentDOM = <EnterpriseDesignerViewPage showHeader designId={designId} design={design} gotoRoot={this.props.redirectToRootPage} switchMode={() => this.switchMode()} {...this.props} />
    } else {
      contentDOM = <EnterpriseDesignerDesignPage designId={designId} design={design} gotoRoot={this.props.redirectToRootPage} switchMode={() => this.switchMode()} {...this.props} />
    }
    return <div className="designer-page-content">{contentDOM}{this.renderFigureConfigDialog(design)}</div>
  }

  switchMode() {
    if (this.props.mode == 'View')
      this.props.setDesignerMode('Design');
    else
      this.props.setDesignerMode('View');
  }

  renderFigureConfigDialog(design) {
    if (design.has('show-config-details')) {
      return <div className='dialog'>
        <div className='dialog-content'>
          <FigureConfigurationDetails designId={this.props.designId}/>
        </div>
      </div>
    }
    return null
  }
}

EnterpriseDesigner.propTypes = {
  mode: PropTypes.string,
  designer: PropTypes.instanceOf(Map).isRequired,
  createDesign: PropTypes.func.isRequired,
  fetchDesignSelectionHints: PropTypes.func.isRequired,
  cancelFigureConfigDetails: PropTypes.func.isRequired,
  acceptFigureConfigDetails: PropTypes.func.isRequired,
  redirectToRootPage: PropTypes.func.isRequired,
  displayDesignList: PropTypes.instanceOf(List).isRequired,
  version: PropTypes.number,
  designId: PropTypes.string,
  description: PropTypes.string,
  showDesignData: PropTypes.func.isRequired,
  saveDesign: PropTypes.func.isRequired,
  setDesignerMode: PropTypes.func.isRequired
}

class EnterpriseDesignerDesignPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    // uncomment to enable performance monitoring
    // monitor();
  }

  renderExport() {
    if (this.state.export) {
      const designString = JSON.stringify(this.props.design.set('id', this.props.designId), null, 2)
      return <div className='dialog'>
          <div className='dialog-content'>
            <CopyToClipboard text={designString}
                                  onCopy={() => this.setState({export: false})}>
              <input type='button' value="Copy to clipboard" />
            </CopyToClipboard>
            <textarea value={designString} style={{width: '100%', height: '500px'}} />
          </div>
        </div>
    }
    return null
  }

  render() {
    const designId = this.props.designId
    const childProps = Object.assign({}, this.props, {
      designId,
      design: this.props.design,
      dataPath: List(['designs', designId])})
    return <div className="design-page">
        {this.renderExport()}
        <CanvasContextMenu/>
        { this.renderHeader(designId) }
        <BreadcrumbTrail/>
        <div className="design-page-content">
          <SplitPane className="design-page-content" split="vertical" primary="first" defaultSize={'20vw'} onDragFinished={(size) => this.props.splitPaneResized('left', size)}>
            <LeftPane {...childProps}/>
            <SplitPane split="vertical" primary="second" defaultSize={'20vw'} paneStyle={{ overflow: 'hidden'}} onDragFinished={(size) => this.props.splitPaneResized('right', size)}>
              {/* by making the key the current designId,
                  we force React to remount the DesignerCanvas component whenever the design changes,
                  completely resetting the canvas state */}
              <DesignerCanvas key={designId} {...childProps} mode='Design'/>
              <RightPane {...childProps}/>
            </SplitPane>
          </SplitPane>
        </div>
        <DesignerModal modalId="designer-modal" componentsMap={modalComponentsMap}/>
      </div>
  }

  renderHeader(designId) {
    return <DesignerHeader
      mode="Design"
      handleRedirectRoot={() => this.props.gotoRoot()}
      handleSwitchMode={() => this.props.switchMode()}
      handleSave={() => this.props.saveDesign(designId)}
      handleExport={() => this.setState({export: true})}/>
  }

}

EnterpriseDesignerDesignPage.propTypes = {
  design: PropTypes.instanceOf(Map).isRequired,
  designId: PropTypes.string.isRequired,
  saveDesign: PropTypes.func,
  gotoRoot: PropTypes.func.isRequired,
  switchMode: PropTypes.func.isRequired,
  splitPaneResized: PropTypes.func.isRequired
}

export class EnterpriseDesignerViewPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      layout: [
        {x: 0, y: 0, w: 12, h: 5, i: 'designer-canvas', isDraggable: false}
      ]
    }
  }

  render() {
    return (
      <DatasourcesPoller>
        <DesignerViewPage
          canvasId={this.props.designId}
          showHeader
          {...this.props}/>
      </DatasourcesPoller>);
  }
}

EnterpriseDesignerViewPage.propTypes = {
  design: PropTypes.instanceOf(Map).isRequired,
  showHeader: PropTypes.bool.isRequired,
  canvasId: PropTypes.string,
  designId: PropTypes.string.isRequired,
  gotoRoot: PropTypes.func.isRequired,
  switchMode: PropTypes.func.isRequired
}

export default connect(
  (state) => {
    const designer = state.designer
    const displayDesignList = designer.get('displayListDesign', List());
    const designId = state.designer.getIn(['selected-design', 'uuid']);
    const version = state.designer.getIn(['web-components', designId, 'version']);
    const description = state.designer.getIn(['web-components', designId, 'description']);
    const splitPane = state.designer.get('splitPane');
    const mode = state.designer.get('mode');
    const refreshCount = state.designer.get('refreshCount', 0);
    return { displayDesignList, designer, designId, version, description, splitPane, mode, refreshCount };
  },
  (dispatch) => {
    return bindActionCreators({
      ...DesignerActions,
      ...DesignerSagaActions,
      ...ComputedPropsActions
    }, dispatch);
  }
)(EnterpriseDesigner);
