import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FigureConfiguration from './FigureConfiguration'
import { List, Map } from 'immutable'
import { isFigureReadonly } from '../../helper/figureConfigHelper';
import { getEvalContext } from '../../helper/selector/datasourceSelectors';
import { primaryFigureSelector,
  altIndexesSelector,
  figureIdentifiersSelector } from '../../helper/selector/selectionSelectors';

import FigureConfigHeader from './FigureConfigHeader';

class DesignerDetails extends React.Component {

  renderFigureConfig(altIndex) {
    return <FigureConfiguration
      key={altIndex}
      designId={this.props.designId}
      altIndex={altIndex}/>
  }

  addFigureConfig() {
    for (let figureIdentifier of this.props.figureIdentifiers) {
      this.props.addFigureConfig(figureIdentifier);
    }
  }

  renderFigureDetails() {
    return <div className="designer-pane-content">
      <FigureConfigHeader designId={this.props.designId}/>
      {!this.props.readonly &&
        <div>
          {this.props.altIndexes.map(this.renderFigureConfig.bind(this))}

          <div className="figure-configuration">
            <input type='button' value='+ Condition' onClick={this.addFigureConfig.bind(this)} />
          </div>
        </div>
      }
    </div>
  }

  renderDetails() {
    if (this.props.primaryFigureConfig) {
      return this.renderFigureDetails()

    } else {
      const evalContextJSON = JSON.stringify(this.props.evalContext, null, 2)
      return <div className="designer-pane-content">
        <label>No figure is selected</label>
        <pre>{evalContextJSON}</pre>
      </div>
    }
  }

  render() {
    if (this.props.primaryFigureConfig) {
      return this.renderFigureDetails()

    } else {
      const evalContextJSON = JSON.stringify(this.props.evalContext, null, 2)
      return <div className="designer-pane-content-viewport">
        <div className="designer-pane-content"><label>No figure is selected</label><pre>{evalContextJSON}</pre></div>
      </div>
    }
  }
}

DesignerDetails.propTypes = {
  designId: PropTypes.string.isRequired,
  designer: PropTypes.instanceOf(Map).isRequired,
  dataPath: PropTypes.instanceOf(List).isRequired,
  addFigureConfig: PropTypes.func.isRequired,

  // connect
  primaryFigureConfig: PropTypes.instanceOf(Map),
  altIndexes: PropTypes.instanceOf(List),
  figureIdentifiers: PropTypes.instanceOf(List),
  selectionType: PropTypes.string,
  readonly: PropTypes.bool,
  evalContext: PropTypes.object.isRequired
}

export default connect(
  (state, { designId }) => {
    const primaryFigureConfig = primaryFigureSelector(state, designId);
    const altIndexes = altIndexesSelector(state, designId);
    const figureIdentifiers = figureIdentifiersSelector(state, designId);
    const readonly = isFigureReadonly(primaryFigureConfig);
    const evalContext = getEvalContext(state.designer, designId);

    return {
      primaryFigureConfig,
      altIndexes,
      figureIdentifiers,
      readonly,
      evalContext
    }
  }
)(DesignerDetails);
