import { Map, List } from 'immutable';
import ActionTypes from './actions';
import DesignerActionTypes from '../datarequest/actions';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.RECEIVE_DESIGN_LIST:
            return handleReceiveDesignList(state, action);
        case ActionTypes.CHANGE_EDITABLE_FIELD_VALUE:
            return handleChangeEditableFieldValue(state, action);
        case ActionTypes.CLEAR_EDITABLE_FIELD_VALUE:
            return handleClearEditableFieldValue(state, action);
        case ActionTypes.REQUEST_UPDATE_DESIGN_PROPERTY:
            return handleRequestUpdateDesignProperty(state, action);
        case ActionTypes.RECEIVE_UPDATE_DESIGN_PROPERTY:
            return handleReceiveUpdateDesignProperty(state, action);
        case ActionTypes.REMOVE_DESIGN:
            return handleRemoveDesign(state, action);
        case ActionTypes.BEGIN_FETCHING:
            return handleBeginFetching(state, action);
        case ActionTypes.COMPLETE_FETCHING:
            return handleCompleteFetching(state, action);
        case ActionTypes.SET_DESIGN_NAME:
            return handleSetDesignName(state, action);
        case ActionTypes.SET_IMPORT_ENABLED:
            return handleSetImportEnabled(state, action);
        case ActionTypes.SET_IMPORT_TEXT:
            return handleSetImportText(state, action);
        case ActionTypes.CLEAR_INPUTS:
            return handleClearInputs(state);
        case DesignerActionTypes.SAVE_DESIGN_RESULT:
            return handleSaveDesignResult(state, action);
    }
    return state;
}

function handleReceiveDesignList(state, { designList }) {
    return state.set('designs', designList);
}

function handleChangeEditableFieldValue(state, { fieldKey, value }) {
    return state.setIn(['editableFields', fieldKey.name], value);
}

function handleClearEditableFieldValue(state, { fieldKey }) {
    return state.deleteIn(['editableFields', fieldKey.name]);
}

function handleRequestUpdateDesignProperty(state, { fieldKey }) {
    return state.setIn(['fetching', fieldKey.name], true);
}

function handleReceiveUpdateDesignProperty(state, { fieldKey, value, version }) {
    const { designId, propertyKey } = fieldKey;
    return state.update('designs', List(), (designs) => designs.map((design) => {
        return design.get('uniqueIdentifier') === designId
            ? design.set('version', version)
                    .set(propertyKey, value)
            : design;
    }));
}

function handleRemoveDesign(state, { designId }) {
    return state.update('designs', List(), (designs) => 
        designs.filter((design) => design.get('uniqueIdentifier') !== designId));
}

function handleBeginFetching(state, { requestId }) {
    return state.setIn(['fetchRequests', requestId], Map({ fetching: true }));
}

function handleCompleteFetching(state, { requestId, error }) {
    if(error) {
        return state.setIn(['fetchRequests', requestId], Map({ fetching: false, error }));
    }
    return state.deleteIn(['fetchRequests', requestId])
}

function handleSetDesignName(state, { designName }) {
    return state.set('designName', designName);
}

function handleSetImportEnabled(state, { enabled }) {
    return state
        .set('importEnabled', enabled)
        .delete('importText');
}

function handleSetImportText(state, { text }) {
    return state.set('importText', text);
}

function handleClearInputs(state) {
    return state
        .delete('importText')
        .delete('designName')
}

function handleSaveDesignResult(state, { designId, result }) {
    return state.update('designs', List(), (designs) => {
        
        let existsInList = false;
        designs = designs.map((design) => {
            if(design.get('uniqueIdentifier') === designId) {
                existsInList = true;
                return result;
            }
            return design;
        });

        if(!existsInList) {
            return designs.push(result);
        }

        return designs;
    });
}
