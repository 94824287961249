import React from 'react';
import PropTypes from 'prop-types';
import Imm from 'immutable';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { createResourcesSelector } from '../../helper/selector/gallerySelectors'
import { resourceShape } from '../../helper/shapes';
import { resourceIdToPath } from '../../helper/figurePathHelper';
import { resetPath, setPath, setSelected } from '../../actions/galleryActions';

const imageExtensions = new Set(['jpg', 'jpeg', 'png', 'gif', 'svg']);

class GalleryView extends React.Component {

    UNSAFE_componentWillMount() {
        this.props.resetPath();
        this.movePrevFolder = this.movePrevFolder.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.searchTerm !== nextProps.searchTerm) {
            this.props.resetPath();
        }
    }

    render() {
        return (
        <React.Fragment>
            Total: { this.props.count }
            <div className="gallery-view">
                { this.props.path && this.props.path.size ? this.renderActionItem('back', this.movePrevFolder) : undefined }
                { this.props.resources.map((resource) => {
                    return <div className="gallery-grid-item" key={resource.id}>
                        { resource.directory ? this.renderFolder(resource) : this.renderFile(resource) }
                    </div>
                })}
            </div>
        </React.Fragment>);
    }

    renderActionItem(text, fn) {
        return (
        <div className="gallery-grid-item" onClick={fn}>
            <div className="gallery-action">
                <div className="gallery-item-icon">
                    <FontAwesome name="arrow-left"/>
                </div>
            </div>
        </div>);
    }

    renderFile(resource) {
        const fileName = resource.fileName;
        const extension = resource.extension;
        const id = resource.id;
        const src = resourceIdToPath(id);
        const showThumbnail = imageExtensions.has(extension);

        const selectedRes = this.props.input.value;
        const selected = selectedRes && selectedRes.id === id ? ' selected' : '';
        
        return (
        <div className={ 'gallery-file' + selected } onClick={() => this.props.input.onChange(resource)}>
            { showThumbnail && <img src={src} alt={fileName} style={{ width: '100%', height: '100%', padding: 5 }}/> }
            <div className="gallery-filename">{ fileName }</div>
            <div className={ 'gallery-extension' + selected }>{ extension }</div>
        </div>);
    }
    
    renderFolder(resource) {
        const fileName = resource.fileName;
        const id = resource.id;
        const path = this.props.path;
        return (
        <div className="gallery-folder" onClick={() => this.props.setPath(path.push(id))}>
            <div className="gallery-item-icon">
                <FontAwesome name="folder"/>
            </div>
            <div className="gallery-filename">{ fileName } </div>
        </div>);
    }

    movePrevFolder() {
        this.props.setPath(this.props.path.pop());
    }
}

GalleryView.propTypes = {
    formValueSelector: PropTypes.func.isRequired,
    input: PropTypes.object.isRequired,

    // connect
    resources: PropTypes.arrayOf(resourceShape).isRequired,
    searchTerm: PropTypes.string,
    path: PropTypes.instanceOf(Imm.List),
    count: PropTypes.number.isRequired,
    resetPath: PropTypes.func.isRequired,
    setPath: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired
}

export default connect(
    () => {
        const resourcesSelector = createResourcesSelector();
        return (state, ownProps) => {
            const formValueSelector = ownProps.formValueSelector;
            const extensions = formValueSelector(state, 'extensions');
            const searchTerm = formValueSelector(state, 'searchTerm');
            return {
                searchTerm,
                ...resourcesSelector(state, { extensions, searchTerm })
            }
        }
    },
    { resetPath, setPath, setSelected }
)(GalleryView);
