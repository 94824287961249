import React from 'react';
import PropTypes from 'prop-types';

import DesignerColorPicker from '../../common/DesignerColorPicker';

/**
 * An input field for rendering "color" type figure-properties
 */

export default function ColorInputComponent(props) {
    const colorPickerId = getColorPickerId(props.fieldKey, props.altIndex);
    return <div className="figure-input-field-container">
        <DesignerColorPicker
            className="figure-input-field-addon"
            value={props.value}
            colorPickerId={colorPickerId}
            onChange={props.onChange}
            alignRight={true}/>
        <input className="figure-input-field" type="text" value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
    </div>
}

function getColorPickerId(fieldKey, altIndex) {
    return `${fieldKey}_${altIndex}`;
}

ColorInputComponent.propTypes = {
    fieldKey: PropTypes.string.isRequired,
    altIndex: PropTypes.number.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}
