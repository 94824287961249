import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel, FormControl, FormText } from 'react-bootstrap';

/**
 * A wrapper around a bootstrap FormGroup to use with redux-form 
 */

export default function FormGroupFieldComponent(props) {
    const { error, warning, touched } = props.meta;
    let validationState, message;
    if(touched) {
        validationState = error ? 'error' : warning ? 'warning' : null;
        message = error || warning || null;
    }

    const inputComponent = renderInputComponent(props.input, props.children, props.inputComponent, props.inputProps);
    return <FormGroup validationState={ validationState }>
        <FormLabel>{ props.label }</FormLabel>
        { inputComponent }
        { message &&
            <FormText className="text-muted">{ message }</FormText> }
    </FormGroup>
}

function renderInputComponent(input, children, inputComponent, inputProps) {
    if(inputComponent == null || typeof inputComponent === 'string') {
        return <FormControl componentClass={inputComponent} {...input}>
                { children }
            </FormControl>
    }
    return React.createElement(inputComponent, { input, ...inputProps });
}

FormGroupFieldComponent.propTypes = {
    label: PropTypes.string.isRequired,
    inputComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    inputProps: PropTypes.object,
    children: PropTypes.node,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
}
