import React from 'react';
import { LoginForm } from '@pearlchain/stackbase-common';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';

export default function LoginPage() {
    const dispatch = useDispatch();
    return (
        <LoginForm onLoginSuccess={() => {
            dispatch(push('/'));
        }}/>
    );
}
