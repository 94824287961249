import React from 'react';
import PropTypes from 'prop-types';
import Imm from 'immutable';
import { connect } from 'react-redux';

import { setFigureName } from '../../actions/DesignerActions';
import { primaryFigureSelector, primaryFigureIdentifierSelector } from '../../helper/selector/selectionSelectors';
import { getFigureName } from '../../helper/figureConfigHelper';
import { figureIdentifierShape } from '../../helper/shapes';

/**
 * Specialized configuration options for canvas figures
 */

function CanvasFigureConfig(props) {
    const figureName = getFigureName(props.figure);

    return <div className="config-header-form">
        <div><label>Figure Name</label></div>
        <input 
            value={figureName || ''} 
            onChange={(e) => props.setFigureName(props.figureIdentifier, e.target.value)}
            />
    </div>
}

CanvasFigureConfig.propTypes = {
    designId: PropTypes.string.isRequired,

    // connect
    figure: PropTypes.instanceOf(Imm.Map).isRequired,
    figureIdentifier: figureIdentifierShape.isRequired,
    setFigureName: PropTypes.func.isRequired
}

export default connect(
    (state, { designId }) => ({
        figure: primaryFigureSelector(state, designId),
        figureIdentifier: primaryFigureIdentifierSelector(state, designId)
    }),
    { setFigureName }
)(CanvasFigureConfig);
