import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { selectFigure, clearSelectedFigure, createFigureOnCanvas } from '../../actions/DesignerActions';
import { getCurrentDesignId, getDesignDefaults } from '../../helper/selector/selectors';
import { figureSelectionStateSelector } from '../../helper/selector/selectionSelectors';
import { createFigureFromDescriptor } from '../../helper/figureConfigHelper';
import DesignerTreeView from '../common/DesignerTreeView';

class FigureDescriptor extends React.Component {

    render() {
        const { figureDescriptor, selectionState } = this.props;

        const id = figureDescriptor.get('id');
        const lbl = decodeURIComponent(figureDescriptor.get('label'));

        return <DesignerTreeView
            nodeId={id}
            label={lbl}
            selectionState={selectionState}
            onClick={() => { 
                // clear if already selected
                if(selectionState === 'primary') {
                    this.createFigureOnCanvas(figureDescriptor);

                // else select
                } else {
                    this.select(id);
                }                    
            }}
        />
    }

    select(id) {
        this.props.selectFigure(this.props.designId, id, [], this.props.type);
    }

    createFigureOnCanvas(figureDescriptor) {
        const figure = createFigureFromDescriptor(this.props.designDefaults, figureDescriptor);
        this.props.createFigureOnCanvas(this.props.designId, figure);
        this.props.clearSelectedFigure(this.props.designId);
    }
}

FigureDescriptor.propTypes = {
    // props
    figureDescriptor: ImmutablePropTypes.map.isRequired,
    type: PropTypes.string.isRequired,

    // connect
    designId: PropTypes.string.isRequired,
    designDefaults: PropTypes.object.isRequired,
    selectionState: PropTypes.object.isRequired,
    selectFigure: PropTypes.func.isRequired,
    clearSelectedFigure: PropTypes.func.isRequired,
    createFigureOnCanvas: PropTypes.func.isRequired
}

export default connect(
    (state, { figureDescriptor }) => { 
        const designId = getCurrentDesignId(state);
        return {
            designId,
            designDefaults: getDesignDefaults(state, designId),
            selectionState: figureSelectionStateSelector(state, designId, figureDescriptor.get('id'))
        }
    }, 
    { selectFigure, clearSelectedFigure, createFigureOnCanvas }
)(FigureDescriptor);
