import { Map } from 'immutable';
import HtmlTextModalContent, { CONTENT_TYPE_TEXT_HTML } from './HtmlTextModalContent';

// built-in modal components
const defaultComponentsMap = Map([
    [CONTENT_TYPE_TEXT_HTML, HtmlTextModalContent]
]);

/**
 * Resolves by id, the component to show in the modal body
 */
export default function(customComponentsMap) {
    const componentsMap = customComponentsMap 
        ? defaultComponentsMap.merge(customComponentsMap) : defaultComponentsMap;

    return (modalContentId) => {
        const component = componentsMap.get(modalContentId);
        if(component == null) {
            throw 'Couldnt find registered modal content with the id: ' + modalContentId;
        }
        return component;
    }
}
