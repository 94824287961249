import React from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as PurchaseActions from '../actions/PurchaseActions'
import Selector from '../../../common/view/components/Selector'
import Master from '../../../common/view/components/Master'
import {newId, initialValues} from '../../../util/form'
import {field, withAssistInDialog, withDatasourceLiveAssist} from '../../../util/field'
import PartnerView from '../../../masterdata/partner/components/PartnerView'

const Datasources = {
  PURCHASE: 'PURCHASE',
  PARTNER: 'PARTNER'
}

const PURCHASE_SELECTOR_CONFIG = fromJS({
  datasources: {
    'main': {
      // the main datasource
      datasource: Datasources.PURCHASE,
      set: {
        no: 'purchaseOrderNo',
        supplierNo: 'supplierNo',
        supplierUuid: 'supplierUuid',
        customerNo: 'customerNo',
        customerUuid: 'customerUuid'
      }
    },
    'supplier': {
      //label: 'Supplier selection',
      datasource: Datasources.PARTNER,
      set: {
        no: 'supplierNo'
      },
      get: {
        supplierNo: 'no',
        supplierName: 'name',
        supplierUuid: 'uniqueIdentifier'
      },
      initial: {
        uniqueIdentifier: 'supplierUuid'
      },
      default: {
        supplier: true
      },
      // make this one unclearable for the search
      retain: ['supplier']
    },
    'customer': {
      //label: 'Customer selection',
      datasource: Datasources.PARTNER,
      set: {
        no: 'customerNo'
      },
      get: {
        customerNo: 'no',
        customerName: 'name',
        customerUuid: 'uniqueIdentifier'
      },
      initial: {
        uniqueIdentifier: 'customerUuid'
      },
      default: {
        supplier: false
      },
      retain: ['supplier']
    }
  },
  layout: {
    columns: 2
  },
  fields: [
   {
     name: 'purchaseOrderNo',
     type: 'text'
     //validations: [ifNullNotNull('password')]
   },
   {
     name: 'purchaseOrderStatus',
     type: 'option',
     limit: '*'
   },
   field({
       name: 'supplierNo',
       type: 'text'
     }, [
     (f) => withDatasourceLiveAssist(f, 'supplier'),
     (f) => withAssistInDialog(f, 'supplier', PartnerView)
   ]),
   {
     name: 'supplierName',
     disabled: true
   }
  ]
})

const PURCHASE_MASTER_CONFIG = fromJS({
  columns: [
   {
      name: 'no',
      type: 'text'
   },
   {
     name: 'partnerNo',
     type: 'text'
   },
   {
     name: 'partnerName',
     type: 'text'
   }
  ]
})

class PurchaseView extends React.Component {

  constructor() {
    super();

    const datasourcesConfig = PURCHASE_SELECTOR_CONFIG.get('datasources')
    this.state = {
      masterId: newId('purchase-master_'),
      selectorProps: {
        formId: newId('purchase-selector_'),
        layout: PURCHASE_SELECTOR_CONFIG.get('layout'),
        fields: PURCHASE_SELECTOR_CONFIG.get('fields'),
        initialValues: initialValues(datasourcesConfig.get('main'), this.props.initialDatasourceValues),
        datasources: datasourcesConfig
      }
    }
  }

  render() {
    const {datasource, selectFn} = this.props
    const selectorProps = this.state.selectorProps
    const masterData = datasource.getIn([selectorProps.formId, 'main'], Map())
    const masterProps = {
      formId: this.state.masterId,
      columns: PURCHASE_MASTER_CONFIG.get('columns'),
      items: masterData.get('items', List()),
      status: masterData.get('status'),
      selectFn
    }
    return <div>
      <h3>Purchase:</h3>
      <Selector {...selectorProps} />
      <Master {...masterProps} />
    </div>
  }

}

PurchaseView.propTypes = {
  datasource: PropTypes.instanceOf(Map).isRequired,
  selectFn: PropTypes.func,
  initialDatasourceValues: PropTypes.instanceOf(Map)
}

export default connect(
  (state) => state,
  (dispatch) => bindActionCreators(PurchaseActions, dispatch)
)(PurchaseView);
