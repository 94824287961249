import Imm from 'immutable';
import { figuresSelector, getPropKeys, getAltConfigPath, figurePathsSelector } from './selectionSelectors';
import { getFigureType } from '../figureConfigHelper';
import { getAvailableFigureProps } from '../DesignerHelper';
import { createSelector } from 'reselect';

export const getConfigDetailsShowing = (state, designId) => state.designer.getIn(['designs', designId, 'show-config-details']);

export function getConfigDetailsAltIndex(state, designId) {
    const configDetails = getConfigDetailsShowing(state, designId);
    return configDetails && configDetails.altIndex;
}

export function getConfigDetailsType(state, designId) {
    const configDetails = getConfigDetailsShowing(state, designId);
    return configDetails && configDetails.type;
}

export function getAvailablePropKeys(figures) {
    if (!figures) return Imm.Set();

    return figures.reduce((acc, figure, idx) => {
        const figureType = getFigureType(figure);
        const availablePropKeys = getAvailableFigureProps(figureType);

        return idx ? acc.intersect(availablePropKeys) : acc.union(availablePropKeys);
    }, Imm.Set());
}

export function getConfigDetailsConfigPaths(figurePaths, altIndex) {
    const altConfigPath = getAltConfigPath(altIndex);
    return figurePaths.map((figurePath) => figurePath.concat(altConfigPath));
}

// shared selectors

export const configDetailsConfigPathsSelector = createSelector(figurePathsSelector, getConfigDetailsAltIndex, getConfigDetailsConfigPaths);

export const availablePropKeysForSelectionSelector = createSelector(figuresSelector, getAvailablePropKeys);

export const propKeysForSelectionSelector = createSelector(figuresSelector, getConfigDetailsAltIndex, getPropKeys);

