import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormLabel, Button, FormGroup, FormText } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

/**
 * A redux-form field wrapper for a simple field array
 */

export default function FormGroupMultiField(props) {
    const { error, warning, touched } = props.meta;
    let validationState, message;
    if(touched) {
        validationState = error ? 'error' : warning ? 'warning' : null;
        message = error || warning || null;
    }

    return <FormGroup validationState={ validationState }>
        <FormLabel style={{ display: 'block' }}>{ props.label }</FormLabel>
        { props.fields.map((field, idx) =>
            <div className="form-group-multi-item" key={field}>
                <Field
                    name={field}
                    component={props.inputComponent}
                    { ...props.inputProps }/>
                <FontAwesome className="btn-remove" name="times" onClick={() => props.fields.remove(idx)}/>
            </div>
        )}
        <Button onClick={() => props.fields.push()}>+</Button>
        { message &&
            <FormText className="text-muted">{ message }</FormText> }
    </FormGroup>
}

FormGroupMultiField.propTypes = {
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    inputComponent: PropTypes.any.isRequired,
    inputProps: PropTypes.object.isRequired
}
