import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EnterpriseDesigner from './EnterpriseDesigner';
import { setSelectedDesign } from '../actions/DesignerActions';
import { showDesignData } from '../datarequest/actions';
import { getCurrentDesignId, getCurrentDesignParams } from '../helper/selector/selectors';
import DesignLoader from './DesignLoader';
import { fromJS } from 'immutable';

/**
 * Enterprise designer route
 * updates the selected design if the query parameters changed
 */

class EnterpriseDesignerRoute extends React.Component {

    UNSAFE_componentWillMount() {
        this.updateCurrentDesign(this.props, true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateCurrentDesign(nextProps, false);
    }

    updateCurrentDesign(nextProps, mounting) {
        const nextDesignId = nextProps.params.designId;
        const nextParams = nextProps.location.query;
        const prevDesignId = this.props.params.designId;
        const prevParams = this.props.location.query;

        // if the location changed, set the current design
        if(mounting || nextDesignId !== prevDesignId || nextParams !== prevParams) {
            this.props.setSelectedDesign(nextDesignId, fromJS(nextParams));
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return <DesignLoader>
            <EnterpriseDesigner/>
        </DesignLoader>
    }
}

EnterpriseDesignerRoute.propTypes = {
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    designId: PropTypes.string,
    designParams: PropTypes.object,
    showDesignData: PropTypes.func.isRequired,
    setSelectedDesign: PropTypes.func.isRequired
}

export default connect(
    (state) => ({
        designId: getCurrentDesignId(state),
        designParams: getCurrentDesignParams(state)
    }),
    { showDesignData, setSelectedDesign }
)(EnterpriseDesignerRoute);
