import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const modalActionShape = ImmutablePropTypes.mapContains({
    label: PropTypes.string.isRequired,
    action: PropTypes.func
})

export const modalContentConfigShape = ImmutablePropTypes.mapContains({
    componentId: PropTypes.string.isRequired,
    params: ImmutablePropTypes.map
});

export const modalConfigShape = ImmutablePropTypes.mapContains({
    title: PropTypes.string.isRequired,
    body: modalContentConfigShape.isRequired,
    actions: ImmutablePropTypes.listOf(modalActionShape).isRequired
});
