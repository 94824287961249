import React from 'react';
import PropTypes from 'prop-types';

import FigureDescriptor from './PaletteFigureDescriptor';
import DesignerTreeView from '../common/DesignerTreeView';

class PaletteGroup extends React.PureComponent {

    render() {
        const label = this.props.folder.get('name');
        return <DesignerTreeView
            defaultCollapsed
            label={label}
            collapseTargetLabel
            createChildren={this.createChildren.bind(this)}
        />
    }

    createChildren() {
        const subFolders = this.props.folder.get('children');
        return subFolders.map((descriptor) => {
            if(descriptor.get('folder')) {
                return <PaletteGroup
                    key={descriptor.get('name')}
                    folder={descriptor}
                    type={this.props.type}/>

            } else {
                return <FigureDescriptor
                    key={descriptor.get('id')}
                    figureDescriptor={descriptor}
                    type={this.props.type}/>
            }
        });
    }
}

PaletteGroup.propTypes = {
    folder: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
}

export default PaletteGroup;
