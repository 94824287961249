import { createSelector } from 'reselect';

export function getImportEnabled(state) {
    return state.designList.get('importEnabled');
}

export function getImportText(state) {
    return state.designList.get('importText');
}

export function getDesignName(state) {
    return state.designList.get('designName');
}

export function getDesignList(state) {
    return state.designList.get('designs')
}

export function getEditableFields(state) {
    return state.designList.get('editableFields');
}

export function getFetching(state, requestId) {
    return state.designList.getIn(['fetchRequests', requestId, 'fetching'], false);
}

export function getEditableField(state, fieldKey) {
    const editableFields = getEditableFields(state);
    return editableFields && editableFields.get(fieldKey.name);
}

export function getDesign(designs, designId) {
    return designs && designs.find((design) => design.get('uniqueIdentifier') === designId);
}

export function getDesignPropertyValue(state, designId, propertyKey) {
    const designs = getDesignList(state);
    const design = getDesign(designs, designId);
    return design && design.get(propertyKey);
}

export const createDesignSelector = (designId) => createSelector(getDesignList, (designs) => getDesign(designs, designId));
