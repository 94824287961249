import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { toNumber } from '../../helper/mathHelper';
import { Checkbox } from 'react-bootstrap';
import FormGroupField from '../common/FormGroupField';
import ColorPickerField from '../common/ColorPickerField';
import CheckboxField from '../common/CheckboxField';

export const CANVAS_CONFIG_FORM = 'CANVAS_CONFIG_FORM'

function CanvasConfigForm(props) {
    return <div>
        <Field label="Canvas Width" component={FormGroupField} name="canvasWidth" validate={validateDimension}/>
        <Field label="Canvas Height" component={FormGroupField} name="canvasHeight" validate={validateDimension}/>
        <Field label="Show Grid" component={CheckboxField} inputComponent={Checkbox} name="showGrid"/>
        { props.showGrid &&
            <Field label="Grid Size" component={FormGroupField} name="gridSize" validate={validateGridSize}/> }
        <Field label="Background Color"
            component={FormGroupField}
            inputComponent={ColorPickerField}
            inputProps={{ colorPickerId: 'bgColor' }}
            name="bgColor"
            validate={validateBgColor}/>
    </div>
}

CanvasConfigForm.propTypes = {
    showGrid: PropTypes.bool
}

function validateGridSize(value) {
    const num = toNumber(value, null);
    if(num == null) {
        return 'Must be numeric';
    }
}

function validateDimension(value) {
    const num = toNumber(value, null);
    if(num == null) {
        return 'Must be numeric';
    }

    if(num <= 0) {
        return 'Must be greater than 0';
    }
}

function validateBgColor(value) {
    if(!value || value.length === 0)
        return 'Color required';
}

export default connect(
    (state, { formValueSelector }) => ({
        showGrid: formValueSelector(state, 'showGrid')
    }))(CanvasConfigForm);