import React from 'react';
import { render } from 'react-dom';

import './styles/sass/main.scss';

// Import Components
import App from './components/App';
import EnterpriseDesigner from './core/designer/components/EnterpriseDesignerRoute';
import PartnerView from './masterdata/partner/components/PartnerView'
import PurchaseView from './procurement/purchase/components/PurchaseView'
import DesignList from './core/designer/components/designlist/DesignList';

// import react router deps
import { Router, Route, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { isAuthenticated } from './component-lib/utils/AuthUtils';
import LoginPage from './LoginPage';

function checkAuthenticated(nextState, replace, callback) {
    if (!isAuthenticated()) {
      history.push('/login');
    }
    
    callback();
}

const router = (
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute component={DesignList} onEnter={checkAuthenticated}/>
        <Route path="designs/:designId" component={EnterpriseDesigner} onEnter={checkAuthenticated}/>
        <Route path="login" component={LoginPage}/>
        <Route path="partner" component={PartnerView}/>
        <Route path="purchase" component={PurchaseView}/>
      </Route>
    </Router>
  </Provider>
);

render(router, document.getElementById('root'));
