import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import Notifications from 'react-notification-system-redux';
import { Button, Form, FormGroup, InputGroup, FormControl, FormLabel, FormCheck  } from 'react-bootstrap';
import uuid from 'uuid';
import { push } from 'react-router-redux';

import { setImportEnabled, setDesignName, setImportText, clearInputs } from '../../designlist/actions';
import { saveDesign } from '../../datarequest/actions';
import { createDesign } from '../../actions/DesignerActions';
import { getImportEnabled, getDesignName, getImportText } from '../../helper/selector/designListSelector';
import DesignListTable from './DesignListTable';
import ModalComponent from '../../../../component-lib/modal/GenericModalComponent';

/**
 * A list of designs shown on the index page
 */

class DesignList extends React.Component {

    render() {
        const designName = this.props.designName;
        return <div className="wrap-panel-page">
            <Form className="panel-full-page">
                <h2>Design Manager</h2>
                <FormGroup>
                    <FormLabel>Create Design</FormLabel>
                    <InputGroup>
                        <FormControl type="text" 
                            value={designName || ''}
                            placeholder="Title"
                            onChange={(e) => this.props.setDesignName(e.target.value)}/>
                            <InputGroup.Append>
                                <Button disabled={!this.hasValidCreationParams} onClick={() => this.createDesign(designName)}>Create</Button>
                            </InputGroup.Append>
                    </InputGroup>
                    <FormCheck 
                        checked={Boolean(this.props.importEnabled)}
                        onChange={(e) => this.props.setImportEnabled(e.target.checked)}
                        label="Import"
                    />
                    { this.props.importEnabled 
                        && <FormControl
                            value={this.props.importText || ''}
                            rows="3"
                            className="textarea-v"
                            componentClass="textarea" 
                            placeholder="paste design data here"
                            onChange={(e) => this.props.setImportText(e.target.value)}
                            /> }
                </FormGroup>
                <DesignListTable chooseDesign={this.chooseDesign.bind(this)}/>
            </Form>
            <ModalComponent modalId="designlist-modal"/>
        </div>
    }

    chooseDesign(designId) {
        const path = '/designs/' + designId;
        this.props.push(path);
    }

    get hasValidCreationParams() {
        return this.props.designName && (!this.props.importEnabled || this.props.importText);
    }

    createDesign(designName) {
      const newUuid = uuid.v4();

      let designContent;
      if(this.props.importEnabled) {
          try {
            designContent = JSON.parse(this.props.importText);
          } catch(e) {
                this.props.showError({ 
                  title: 'Import error', 
                  message: 'Error importing design. Invalid JSON', 
                  autoDismiss: 5
                });
                return;
          }
      }
      
      this.props.createDesign(newUuid, fromJS(designContent));
      this.props.saveDesign(newUuid, designName);
      this.props.clearInputs();
    }
}

DesignList.propTypes = {
    designName: PropTypes.string,
    importEnabled: PropTypes.bool,
    importText: PropTypes.string,
    setImportEnabled: PropTypes.func.isRequired,
    setImportText: PropTypes.func.isRequired,
    createDesign: PropTypes.func.isRequired,
    saveDesign: PropTypes.func.isRequired,
    setDesignName: PropTypes.func.isRequired,
    clearInputs: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
}

export default connect(
    (state) => ({
        designName: getDesignName(state),
        importEnabled: getImportEnabled(state),
        importText: getImportText(state)
    }),
    {  setImportEnabled,
        createDesign, 
        saveDesign, 
        setDesignName, 
        setImportText, 
        clearInputs, 
        showError: Notifications.error,
        push }
)(DesignList);
