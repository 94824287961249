import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getBaseFigureDescriptorsFolder } from '../../helper/selector/figureDescriptorsSelector';
import { getCustomFigureDescriptorsFolder } from '../../helper/selector/figureDescriptorsSelector';
import { fetchFigureDescriptors } from '../../datarequest/actions';
import PaletteGroup from './PaletteGroup';
/*global draw2d*/

class DesignerPalette extends React.Component {
  
  UNSAFE_componentWillMount() {
    this.props.fetchFigureDescriptors();
  }

  render() {
    return <div className="designer-pane-content">
      <PaletteGroup folder={this.props.baseFigureDescriptors} type="base"/>
      <PaletteGroup folder={this.props.customFigureDescriptors} type="custom"/>
    </div>
  }
}

DesignerPalette.propTypes = {
  baseFigureDescriptors: PropTypes.instanceOf(Map).isRequired,
  customFigureDescriptors: PropTypes.instanceOf(Map).isRequired,
  fetchFigureDescriptors: PropTypes.func.isRequired
}

export default connect(
  (state) => ({
    baseFigureDescriptors: getBaseFigureDescriptorsFolder(state),
    customFigureDescriptors: getCustomFigureDescriptorsFolder(state)
  }),
  { fetchFigureDescriptors }
)(DesignerPalette)
