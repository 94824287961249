import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

import DesignerColorPicker from './DesignerColorPicker';

/**
 * A redux-form field component with an attached color picker
 */

class ColorPickerField extends React.Component {
    render() {
        return <div>
            <FormControl {...this.props.input}/>
            <DesignerColorPicker
                value={this.props.input.value}
                colorPickerId={this.props.colorPickerId}
                onChange={this.handleColorPickerValueChanged.bind(this)}/>
            </div>
    }

    handleColorPickerValueChanged(value) {
        this.props.input.onChange(value);
    }
}

ColorPickerField.propTypes = {
    colorPickerId: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired
}

export default ColorPickerField;
