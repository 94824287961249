import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Main from './Main';

function mapStateToProps(state) {
  return {
    designer: state.designer
  }
}

function mapDispachToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const App = connect(mapStateToProps, mapDispachToProps)(Main);

export default App;
