import { Map } from 'immutable';

export const transformDefinitionKeys = ['GROUP_BY', 'SELECT', 'COUNT', 'SUM', 'LIMIT', 'SORT_BY', 'FILTER'];

export const transformDefinitions = Map([
    ['SELECT', {
        label: 'Select',
        params: [
            { key: 'fields', label: 'Fields', type: 'dropdown-column', multi: true },
            { key: 'default', label: 'Default Value', optional: true, defaultValue: '' }
        ]
    }],
    ['GROUP_BY', { 
        label: 'Group By',
        params: [
            { key: 'group-by', label: 'Group By', type: 'dropdown-column' },
            { key: 'alias', label: 'Alias', defaultValue: 'group' }
        ]
    }],
    ['COUNT', {
        label: 'Count',
        params: [
            { key: 'alias', label: 'Alias', defaultValue: 'count' }
        ]
    }],
    ['SUM', {
        label: 'Sum',
        params: [
            { key: 'sum-by', label: 'Sum Field', type: 'dropdown-column' },
            { key: 'alias', label: 'Alias', defaultValue: 'sum' }
        ]
    }],
    ['LIMIT', {
        label: 'Limit',
        params: [
            { key: 'limit', label: 'Limit', defaultValue: '0' }
        ]
    }],
    ['SORT_BY', {
        label: 'Sort By',
        params: [
            { key: 'sort-by', label: 'Sort-by Field', type: 'dropdown-column' }
        ]
    }],
    ['FILTER', {
        label: 'Filter',
        params: [
            { key: 'field', label: 'Field', type: 'dropdown-column' },
            { key: 'comp', label: 'Comparison Type', defaultValue: 'EQUAL' },
            { key: 'value', label: 'Value' }
        ]
    }]
]);
