import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import Navigation from './Navigation';

require('isomorphic-fetch');

const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px'
        }
      }
    };

const Main = function(props) {
  const { notifications } = props;
  return <div className="app">
      <Navigation />
      {notifications ? <Notifications notifications={notifications} style={style} /> : ''}
      { React.cloneElement(props.children, props) }
    </div>
};

Main.propTypes = {
  children: PropTypes.any.isRequired,
  notifications: PropTypes.array
}

Main.displayName = 'Main'

export default connect(
  (state) => ({ notifications: state.notifications })
)(Main);
