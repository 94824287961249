import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

import { getEditableField } from '../../helper/selector/designListSelector';
import { changeEditableFieldValue,
         clearEditableFieldValue } from '../../designlist/actions';

/**
 * A customizable component for rendering editable fields.
 * Has an edit button that shows text input. 
 */

class EditableField extends React.Component {

    componentWillUnmount() {
        this.clearEditInput();
    }

    render() {
        return typeof(this.props.fieldInput) === 'undefined'
            ? this.renderValue()
            : this.renderInput();
    }

    renderValue() {
        const value = this.props.value;
        return <span>
            { this.props.componentFn
                ? this.props.componentFn(value) : value }
            <FontAwesome className="icon-field-inline" name="edit" onClick={() => this.changeEditInput(value)}/>
        </span>
    }

    renderInput() {
        return <span>
            <input type="text"
                ref={(dom) => dom && dom.focus()}
                value={ this.props.fieldInput } 
                onKeyUp={(e) => this.handleKeyUp(e)}
                onChange={(e) => this.changeEditInput(e.target.value)}/>
            <FontAwesome className="icon-field-inline" name="times-circle" onClick={() => this.clearEditInput()}/>
        </span>
    }

    changeEditInput(value) {
        this.props.changeEditableFieldValue(this.props.fieldKey, value);
    }

    clearEditInput() {
        this.props.clearEditableFieldValue(this.props.fieldKey);
    }

    handleKeyUp(e) {
        switch(e.keyCode) {
            case 13: {
                // save when enter key pressed
                this.clearEditInput();
                this.props.saveValue(this.props.fieldInput);
                break;
            }
            case 27: {
                // cancel when escape key pressed
                this.clearEditInput();
            }
        }
    }
}

EditableField.propTypes = {
    // ownprops
    fieldKey: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    saveValue: PropTypes.func.isRequired,
    componentFn: PropTypes.func,
    componentProps: PropTypes.object,

    fieldInput: PropTypes.string,
    changeEditableFieldValue: PropTypes.func.isRequired,
    clearEditableFieldValue: PropTypes.func.isRequired
}

export default connect(
    (state, { fieldKey }) => ({
        fieldInput: getEditableField(state, fieldKey)
    }), 
    { changeEditableFieldValue, clearEditableFieldValue }
)(EditableField);
