import React from 'react';
import PropTypes from 'prop-types';
import TreeView from 'react-treeview';

/**
 * A collapsible tree-view.
 */

export default class DesignerTreeView extends React.PureComponent {

    constructor(props) {
        super();
        this.state = { collapsed: !!props.defaultCollapsed }
    }

    render() {
        return this.props.createChildren
            ? this.renderTreeView()
            : this.renderTreeChild();
    }

    renderTreeChild() {
        const { label, labelAddOn, onClick } = this.props;
        const className = this.getSelectionClass();

        return <div className={'treeview-child' + className}>
                <div className="treeview-child-text" onClick={onClick}>
                    { label }
                </div>
                { labelAddOn && 
                    <div className="treeview-child-addon">
                        { labelAddOn }
                    </div>
                }
            </div>
    }

    renderTreeView() {
        const { createChildren } = this.props;
        const collapsed = this.state.collapsed;
        const labelComponent = this.createLabelComponent();
        const className= this.getSelectionClass();

        return <TreeView
            onClick={this.toggleCollapsed.bind(this)}
            nodeLabel={labelComponent}
            collapsed={collapsed} 
            itemClassName={className}>

            { !collapsed && createChildren() }
        </TreeView>
    }

    createLabelComponent() {
        const { label, onClick, labelAddOn, collapseTargetLabel } = this.props;

        let clickHandler = onClick;
        if(!onClick && collapseTargetLabel) {
            clickHandler = this.toggleCollapsed.bind(this);
        }

        return <div className='treeview-label'>
            <div className="treeview-label-text" onClick={clickHandler}>
                { label }
            </div>
            { labelAddOn && 
                <div className="treeview-label-addon">
                    { labelAddOn }
                </div>
            }
        </div> 
    }

    toggleCollapsed() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    getSelectionClass() {
        const selectionState = this.props.selectionState;
        switch(selectionState) {
            case 'primary': return ' primary-selected';
            case 'secondary': return ' secondary-selected';
            case 'child-primary':
            case 'child-secondary': return ' child-selected';
            default: return '';
        }
    }
}

DesignerTreeView.propTypes = {
    // ownprops
    defaultCollapsed: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelAddOn: PropTypes.node,
    selectionState: PropTypes.string,
    createChildren: PropTypes.func,
    onClick: PropTypes.func,
    collapseTargetLabel: PropTypes.bool,

    collapsed: PropTypes.bool
}
