import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { stackbaseReducers } from '@pearlchain/stackbase-common';

import designer, { designerFormReducer } from '../core/designer/reducers/DesignerReducers';
import designListReducers from '../core/designer/designlist/reducers';
import partner from '../masterdata/partner/reducers/PartnerReducers';
import purchase from '../procurement/purchase/reducers/PurchaseReducers';
import datasourceReducer from '../common/view/reducers/DatasourceReducer';
import {reducer as notifications} from 'react-notification-system-redux';
import componentLibReducer from '../component-lib/reducers';

const combineReducer = combineReducers({
    designer,
    designList: designListReducers,
    partner,
    purchase,
    form: formReducer,
    datasource: datasourceReducer,
    stackbase: stackbaseReducers,
    routing: routerReducer,
    notifications,
    componentLib: componentLibReducer
});

const rootReducer = function(state, action) {
  state = combineReducer(state, action);
  state = designerFormReducer(state, action);
  return state
};

export default rootReducer
