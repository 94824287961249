import React from 'react';
import PropTypes from 'prop-types';

export default function DesignerPaneComponent(props) {
    return <div className="designer-pane">
        <div className="designer-pane-title">{ props.title }</div>
        <div className="designer-pane-content-viewport">
            { props.children }
        </div>
    </div>
}

DesignerPaneComponent.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}
